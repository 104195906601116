import moment from 'moment';

import dateFormatter from 'utils/date_formatter';
import isCarProperty from 'utils/is_car_property';

export const buildAccommodationUnits = (propertyUnits, params) => {
  if (!propertyUnits || !params) return [];
  const { ratesOccupancyPerUnit } = params;

  const bookedUnits = Object.keys(ratesOccupancyPerUnit).reduce((unitsList, unitTypeCode) => {
    const selectedRates = ratesOccupancyPerUnit[unitTypeCode];
    const unit = propertyUnits.find(unit => unitTypeCode === unit.id);

    const bookedPerRoomId = Object.keys(selectedRates).reduce((acc, ratePlanCode) => {
      const rateSelectedAmount = selectedRates[ratePlanCode];
      const ratePlan = unit.ratePlans.find(rate => ratePlanCode === rate.id);
      const { occupancy, cancellationPolicy, mealType, title } = ratePlan;

      const bookedUnitsPerRate = new Array(rateSelectedAmount).fill(null).map(() => {
        return {
          cancellationPolicy,
          mealType,
          roomRateTitle: title,
          unitTypeCode,
          ratePlanCode,
          occupancy,
          title: unit.title,
          guests: [{ name: '', surname: '', nationality: '' }],
        };
      });
      return [...acc, ...bookedUnitsPerRate];
    }, []);

    return [...unitsList, ...bookedPerRoomId];
  }, []);

  return bookedUnits;
};

const formatGuest = (guest) => {
  if (!guest.name && !guest.surname) {
    guest.name = 'No';
    guest.surname = 'Name';
  } else {
    if (!guest.name) guest.name = '';
    if (!guest.surname) guest.surname = '';
  }
  if (!guest.nationality) guest.nationality = '';
  return guest;
}

export const prefillUnitsInfo = (propertyUnits) =>
  propertyUnits.map(propertyUnit => {
    const guests = propertyUnit.guests.map(formatGuest);

    const { unitTypeCode, ...rest } = propertyUnit;
    return {
      ...rest,
      guests,
      roomTypeCode: unitTypeCode,
    }
  });

const formatCardInfo = cardInfo => {
  const { expirationMonth, expirationYear, serviceCode, ...rest } = cardInfo;
  const expirationDate = `${expirationMonth}/${expirationYear}`;

  return { ...rest, expirationDate, cvv: serviceCode };
};

const buildVehicleBooking = (bookingParams) => {
  const { bookedUnits, dates, pickupTime, dropoffTime, driverAge } = bookingParams;
  const { occupancy } = bookedUnits;
  const { checkinDate, checkoutDate } = dates;
  const pickupDate = dateFormatter.toApi(checkinDate);
  const dropoffDate = dateFormatter.toApi(checkoutDate);

  const vehicleDriver = occupancy?.[0]?.guests?.[0];

  const { name, surname, ...rest } = vehicleDriver;

  const driver = {
    ...rest,
    firstName: name,
    lastName: surname,
    age: driverAge,
  };

  return {
    pickupDate,
    dropoffDate,
    pickupTime,
    dropoffTime,
    notes: '',
    rateId: occupancy?.[0]?.id,
    driver,
  };
}

const buildAccommodationBooking = (bookingParams) => {
  const { property, bookedUnits, dates, user, requestCreditCard, cardInfo } = bookingParams;
  const { propertyPolicy, currency } = property;
  const { occupancy, externalReferenceId, reservationName, specialRequest } = bookedUnits;
  const { checkinDate, checkoutDate } = dates;
  const checkIn = dateFormatter.toApi(checkinDate);
  const checkOut = dateFormatter.toApi(checkoutDate);
  const guarantee = requestCreditCard ? formatCardInfo(cardInfo) : null;

  const { email, phone, address_1, country, city, state, zipCode, name } = user.agent;

  const booking = {
    status: 'new',
    currency,
    hotelPolicy: propertyPolicy,
    customer: {
      surname: name,
      ...(email && { mail: email }),
      ...(phone && { phone }),
      ...(address_1 && { address: address_1 }),
      ...(country && { country }),
      ...(city && { city }),
      ...(state && { state }),
      ...(zipCode && { zipCode }),
      ...((externalReferenceId || reservationName || specialRequest) && {
        meta: {
          ...(externalReferenceId && { externalReferenceId }),
          ...(reservationName && { reservationName }),
          ...(specialRequest && { specialRequest }),
        },
      }),
    },
    externalReference: externalReferenceId,
    reservationName,
    specialRequest,
    guarantee,
  };

  booking.rooms = prefillUnitsInfo(JSON.parse(JSON.stringify(occupancy)));

  booking.arrivalDate = checkIn;
  booking.departureDate = checkOut;


  return booking;
}

export const buildBooking = (bookingParams, propertyType) => {
  if (isCarProperty(propertyType)) {
    return buildVehicleBooking(bookingParams);
  }

  return buildAccommodationBooking(bookingParams);
};

export default buildBooking;
