import React, { useContext, useEffect, useMemo } from 'react';
import { Alert, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/logo.svg';

import ApiActions from 'api_actions';
import routes from 'routing/routes';
import USER_ROLE from 'constants/user_role';
import MainSearch from 'components/main_search';

import { AppDataContext, AuthenticationDataContext } from 'containers/data_context';
import { StripeActionsContext, StripeDataContext } from 'containers/data_context';
import { SearchActionsContext, SearchDataContext } from 'containers/data_context';

import EmptyIcon from 'static/empty-property.svg';
import styles from './home_page.module.scss';
import { PROPERTY_CHANNELS } from 'constants/property_channels';
import stripeSubscription from 'utils/stripe_subscription';
import notification from 'utils/notification/notification';
import classNames from 'classnames';

const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { featureFlags } = useContext(AppDataContext);
  const { userRole, user } = useContext(AuthenticationDataContext);
  const { checkResellerPaymentSource } = useContext(StripeActionsContext);
  const { stripeNotification } = useContext(StripeDataContext);

  if (userRole !== USER_ROLE.RESELLER) {
    history.push(routes.bookingsPage);
    return null;
  }

  if (!featureFlags.searchPageIsActive) {
    return (
      <div className={styles.emptyWrapper}>
        <img src={EmptyIcon} alt={t('properties.unActiveSearchPage')} />
      </div>
    );
  }

  const stripeCustomerPortalLink = async () => {
    let response;
    try {
      response = await ApiActions.getResellerStripeCustomerPortalUrl();
    } catch {
      notification.withIcon('error', t('general.stripe_cutomer_portal_error_message'));
    }

    window.open(response.stripeCustomerPortalSessionUrl, '_blank');
  };

  const { clearPropertiesList, loadAllPropertiesList } = useContext(SearchActionsContext);
  const { allProperties } = useContext(SearchDataContext);
  const { data: allPropertiesData, isLoading } = allProperties;

  const navbarLocked = useMemo(() => {
    return stripeSubscription.checkLockReseller(user);
  }, [user]);

  const navbarWarning = useMemo(() => {
    return stripeSubscription.checkWarningReseller(user);
  }, [user]);

  const searchContainerStyle = () => {
    if (!navbarLocked) return styles.search_container;

    return classNames(styles.search_container, styles.lower_margin);
  };

  useEffect(() => {
    if (!navbarLocked && navbarWarning)
      notification.withIcon('warning', t('general.stripe_warning'), t('general.stripe_warning_description'));
  }, [navbarLocked, navbarWarning]);

  useEffect(() => {
    checkResellerPaymentSource();
    if (stripeNotification)
      notification.withIcon('warning', t('general.stripe_notification'), t('general.stripe_notification_description'));
  }, [stripeNotification]);

  return (
    <div>
      {navbarLocked && (
        <div className={styles.alert_container}>
          <Alert
            message={t('general.stripe_locked')}
            showIcon
            description={t('general.stripe_locked_description')}
            type="error"
            action={
              <Button size="small" danger onClick={stripeCustomerPortalLink}>
                {t('general.stripe_subscription_details')}
              </Button>
            }
          />
        </div>
      )}
      <div className={styles.container}>
        <div className={searchContainerStyle()}>
          <div className={styles.logo_container}>
            <Logo />
          </div>
          <div className={styles.subTitle}>
            <div className={styles.sub_title_text}>Marketplace for suppliers and resellers</div>
          </div>
          <MainSearch
            propertyType={PROPERTY_CHANNELS.HOTEL}
            loadDataList={loadAllPropertiesList}
            clearDataList={clearPropertiesList}
            dataList={allPropertiesData}
            isLoading={isLoading}
            locked={navbarLocked}
          />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
