import React, { useCallback, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';

import { IMapProps } from './map.types';

const DEFAULT_ZOOM = 13;
const BOOTSTRAP_URL_KEYS = { key: process.env.REACT_APP_GOOGLE_MAP_KEY || '' };

const parseLocationCoords = ({ latitude, longitude }: IMapProps['location']) => {
  if (!latitude || !longitude) {
    return null;
  }

  return {
    lat: Number(latitude),
    lng: Number(longitude),
  };
};

const Map: React.FC<IMapProps> = ({ location, isMarkerDraggable, onCoordinatesChange }) => {
  const [parsedLocation, setParsedLocation] = useState<{ lat: number; lng: number } | null>(null);

  const handleMapLoading = useCallback(
    ({ map, maps }) => {
      const newMarker = new maps.Marker({
        position: parsedLocation,
        map,
        draggable: isMarkerDraggable,
      });

      if (isMarkerDraggable) {
        maps.event.addListener(newMarker, 'dragend', () => {
          const updatedCoordinates = { lat: newMarker.getPosition().lat(), lng: newMarker.getPosition().lng() };
          setParsedLocation(updatedCoordinates);
          if (onCoordinatesChange) {
            onCoordinatesChange(updatedCoordinates);
          }
        });
      }

      return newMarker;
    },
    [parsedLocation],
  );

  const initMapOptions = useCallback(maps => {
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.TOP_LEFT,
      },
    };
  }, []);

  useEffect(
    function initLocation() {
      const newParsedLocation = parseLocationCoords(location);

      setParsedLocation(newParsedLocation);
    },
    [location],
  );

  if (!parsedLocation) {
    return null;
  }

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
        options={initMapOptions}
        center={parsedLocation}
        zoom={DEFAULT_ZOOM}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={handleMapLoading}
        draggable={true}
      />
    </div>
  );
};

export default Map;
