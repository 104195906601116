import React, { useCallback, useContext, useEffect, useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Form } from 'antd';

import Panel from 'components/layout/panel';
import { BookingCheckoutActionsContext } from 'containers/data_context';
import { AuthenticationDataContext } from 'containers/data_context';

import isAccommodationProperty from 'utils/is_accommodation_property';
import { buildBooking, buildAccommodationUnits } from './utils/build_booking';

import ErrorModal from './error_modal';
import GuestInfo from './guest_info';
import CardCaptureForm from './card_capture_form';
import SubmitSection from './submit_section';
import notification from 'utils/notification';

import { IBookingCheckoutFormProps, IBookingUnit } from './booking_checkout_form.types';

const { TextArea } = Input;

const DEFAULT_GUEST = { name: '', surname: '', nationality: '' };

const BookingCheckoutForm: React.FC<IBookingCheckoutFormProps> = ({
  channelId,
  property,
  params,
  units,
  total,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const { createBooking, setFormSubmitComplete } = useContext(BookingCheckoutActionsContext);
  const { user } = useContext(AuthenticationDataContext);

  const buildVehicleUnits = (units: IBookingUnit[]) => {
    return [
      { ...units?.[0]?.ratePlans?.[0], guests: [{ ...DEFAULT_GUEST, address: { countryCode: params.driverCountry } }] },
    ];
  };

  const buildUnits = (): IBookingUnit[] => {
    if (isAccommodationProperty(params.type)) {
      return buildAccommodationUnits(units, params) as IBookingUnit[];
    }
    return buildVehicleUnits(units) as IBookingUnit[];
  };

  const [unitsOccupancy, setUnitsOccupancy] = useState<IBookingUnit[]>(buildUnits());

  const isAccomodation = useMemo(() => isAccommodationProperty(params.type), [params.type]);

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const captureFormRef = useRef() as any;

  const [isErrorModalVisible, setErrorModalVisibility] = useState(false);

  const [form] = Form.useForm();

  const requestCreditCard = useMemo(
    () =>
      !!unitsOccupancy?.find(
        unit => unit?.cancellationPolicy && unit?.cancellationPolicy?.guaranteePaymentPolicy !== 'none',
      ),
    [units],
  );

  useEffect(() => {
    form.setFieldsValue({
      specialRequest: '',
      externalReferenceId: '',
      reservationName: '',
      pickUpTime: null,
      dropOffTime: null,
      unitsOccupancy,
    });
  }, []);

  useEffect(() => {
    const units = buildUnits();
    setUnitsOccupancy(units);
  }, [params, units]);

  const handleSubmitError = useCallback(() => {
    setFormSubmitComplete();
  }, [setFormSubmitComplete]);

  const toggleErrorModal = useCallback(() => {
    handleSubmitError();
    setErrorModalVisibility(!isErrorModalVisible);
  }, [isErrorModalVisible, handleSubmitError]);

  const handleAddUnitGuest = (unitIndex: number) => {
    const updatedUnits = JSON.parse(JSON.stringify(unitsOccupancy));
    updatedUnits[unitIndex] = {
      ...updatedUnits[unitIndex],
      guests: [...updatedUnits[unitIndex].guests, DEFAULT_GUEST],
    };
    setUnitsOccupancy(updatedUnits);
  };

  const handleDeleteUnitGuest = (unitIndex: number, guestIndex: number) => {
    const updatedUnits = JSON.parse(JSON.stringify(unitsOccupancy));
    updatedUnits[unitIndex].guests = [
      ...updatedUnits[unitIndex].guests.slice(0, guestIndex),
      ...updatedUnits[unitIndex].guests.slice(guestIndex + 1),
    ];

    setUnitsOccupancy(updatedUnits);
  };

  const onFinish = useCallback(
    async cardInfo => {
      if (requestCreditCard && !cardInfo) {
        toggleErrorModal();
        return;
      }

      try {
        const formData = await form.validateFields();
        const occupancyData = unitsOccupancy.map((unit: IBookingUnit, index: number) => {
          return {
            ...unit,
            guests: formData.unitsOccupancy[index].guests,
          };
        });
        const bookedUnits = {
          specialRequest: formData.specialRequest || '',
          occupancy: occupancyData,
          externalReferenceId: formData.externalReferenceId || null,
          reservationName: formData.reservationName || '',
        };

        const bookingParams = {
          property: {
            currency: property.currency,
            propertyPolicy: property.hotelPolicy,
          },
          bookedUnits,
          dates: {
            checkinDate: params.checkinDate || params.pickupDate,
            checkoutDate: params.checkoutDate || params.dropoffDate,
          },
          pickupTime: params.pickupTime,
          dropoffTime: params.dropoffTime,
          driverAge: params.driverAge,
          user,
          requestCreditCard,
          cardInfo,
        };

        const booking = buildBooking(bookingParams, params.type);

        try {
          const bookingParams = await createBooking(channelId, params.type, booking, {
            hotelName: property.title,
            total,
            propertyChannelId: channelId,
          });

          onSuccess(bookingParams);
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        } catch (error: any) {
          notification.withIcon('error', t('general.error_message'), error.message);
          setFormSubmitComplete();
        }
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      } catch (err: any) {
        onFinishFailed(err);
      }
    },
    [channelId, createBooking, requestCreditCard, captureFormRef, property, units, params, toggleErrorModal, onSuccess],
  );

  const handleCaptureFormSubmitted = useCallback(
    submitEvent => {
      const { card } = submitEvent;

      onFinish(card);
    },
    [onFinish, form],
  );

  const onFinishFailed = ({ errorFields }: { errorFields: Array<{ name: string }> }) => {
    form.scrollToField(errorFields?.[0].name);
  };

  const handleCaptureFormValidated = useCallback(
    async ({ valid }) => {
      if (!valid) {
        return;
      }
    },
    [onFinish, handleSubmitError],
  );

  return (
    <div data-testid="BookingCheckoutForm">
      <Form form={form}>
        {isAccomodation && (
          <>
            <Panel title={t('payment_page.payment_form.customer_info.special_request')}>
              <Form.Item name="specialRequest">
                <TextArea rows={4} name="specialRequest" />
              </Form.Item>
            </Panel>
            <Panel title={t('bookings_list.reservation_name_title')}>
              <Form.Item name="reservationName">
                <Input size="large" />
              </Form.Item>
            </Panel>
            <Panel title={t('payment_page.payment_form.customer_info.external_id')}>
              <Form.Item name="externalReferenceId">
                <Input size="large" />
              </Form.Item>
            </Panel>
          </>
        )}
        <GuestInfo
          units={unitsOccupancy}
          addGuestForRoom={handleAddUnitGuest}
          deleteGuest={handleDeleteUnitGuest}
          propertyType={params.type}
        />
        <CardCaptureForm
          propertyType={params.type}
          visible={requestCreditCard}
          ref={captureFormRef}
          onSubmit={handleCaptureFormSubmitted}
          onValidate={handleCaptureFormValidated}
        />
        <SubmitSection
          onSubmit={() => (requestCreditCard ? captureFormRef?.current?.submit() : onFinish(captureFormRef?.current))}
        />
      </Form>
      <ErrorModal visible={isErrorModalVisible} onClose={toggleErrorModal} />
    </div>
  );
};

export default BookingCheckoutForm;
