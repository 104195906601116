// Generated with util/create-component.js
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Select, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { HomeOutlined, PushpinOutlined } from '@ant-design/icons';

import {
  IPlaceOptionType,
  IPlaceSettingsProps,
  IPlaceOptions,
  IPlaceOptionDetails,
  IPlacePropertyOptionDetails,
} from './place_settings.types';
import styles from './place_settings.module.scss';

const { Option, OptGroup } = Select;

const PlaceSettings: React.FC<IPlaceSettingsProps> = ({
  propertiesData = {},
  isLoading,
  searchTerm,
  bordered = true,
  onSelectOption,
  onSearch,
}) => {
  const { t } = useTranslation();

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const placeSettingsRef: React.RefObject<any> = React.createRef();

  const renderLoading = useCallback(() => {
    const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

    return (
      <div className={styles.loadingContainer} data-testid="Loading">
        <Spin indicator={antIcon} />
        {t('general.other.loading')}
      </div>
    );
  }, []);

  const handleOptionChange = (value: string, option: { type: IPlaceOptionType }) => {
    placeSettingsRef?.current?.blur();
    onSelectOption(value, option);
  };

  return (
    <div data-testid="PlaceSettings" className={styles.root}>
      <Select
        ref={placeSettingsRef}
        showSearch
        allowClear
        value={searchTerm || null}
        bordered={bordered}
        onSelect={handleOptionChange}
        loading={isLoading}
        size="large"
        className={styles.place_dropdown}
        onClear={() => onSelectOption('', { type: null })}
        placeholder={t('general.where_are_you_going')}
        optionFilterProp="value"
        {...(isLoading ? { dropdownRender: renderLoading } : {})}
        onSearch={onSearch}
      >
        {(Object.keys(propertiesData || {}) || [])?.map(
          (placeType: string, ind: number) =>
            (
              propertiesData?.[placeType as keyof IPlaceOptions] as (
                | IPlaceOptionDetails
                | IPlacePropertyOptionDetails
              )[]
            )?.length && (
              <OptGroup label={placeType?.replaceAll('_', ' ').toUpperCase()} key={`${placeType}-${ind}`}>
                {(
                  propertiesData?.[placeType as keyof IPlaceOptions] as (
                    | IPlaceOptionDetails
                    | IPlacePropertyOptionDetails
                  )[]
                )?.map((item: IPlaceOptionDetails | IPlacePropertyOptionDetails, index: number) => (
                  <Option value={item?.title} key={`place-group-option-${placeType}-${index}`} type={placeType}>
                    <div className={styles.option_wrapper}>
                      <div className={styles.option_icon}>
                        {placeType === 'property' ? <HomeOutlined /> : <PushpinOutlined />}
                      </div>
                      <div>
                        <div>{item?.title}</div>
                        {item?.info && <div className={styles.option_info}>{item?.info}</div>}
                      </div>
                    </div>
                  </Option>
                ))}
              </OptGroup>
            ),
        )}
      </Select>
    </div>
  );
};

export default PlaceSettings;
