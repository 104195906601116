// Generated with util/create-component.js
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Divider, Typography, Space, Table, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TablePaginationConfig } from 'antd/es/table/Table';
import Modal from 'antd/lib/modal/Modal';

import DATE_FORMATTER from 'utils/date_formatter';
import buildPath from 'utils/build_path';
import routes from 'routing/routes';
import GIFT_CAMPAIGN_STATUS from 'constants/gift_campaign_status';

import { IGiftCampaign } from 'components/gift_campaign_form/gift_campaign_form.types';
import SearchInput from 'components/search_input';

import { IGiftCampaignsListProps } from './gift_campaigns_list.types';
import styles from './gift_campaigns_list.module.scss';

const { Link } = Typography;

const COLUMN_WIDTH = window.innerWidth > 900 ? '25%' : 150;

const GiftCampaignsList: React.FC<IGiftCampaignsListProps> = ({
  giftCampaigns,
  isLoading,
  pagination,
  onChange,
  onOpenGiftCampaign,
  onUpdateGiftCampaign,
}) => {
  const { t } = useTranslation();

  const [filteredGC, setFilteredGC] = useState(giftCampaigns);
  const [isModalVisible, toggleModalVisibility] = useState<boolean>(false);
  const [giftCampaign, setGiftCampaign] = useState<IGiftCampaign | null>(null);

  useEffect(() => {
    setFilteredGC(giftCampaigns);
  }, [giftCampaigns]);

  const handleOpenGiftCampaignPage = (giftCampaign: IGiftCampaign) => {
    const giftCampaignPath = buildPath('', routes.giftCampaign, { giftCampaignId: giftCampaign.publicId });
    window.open(giftCampaignPath, '_blank');
  };

  const handleUpdateGiftCampaign = async () => {
    if (giftCampaign) {
      const updatedStatus =
        giftCampaign.status === GIFT_CAMPAIGN_STATUS.PUBLISHED
          ? GIFT_CAMPAIGN_STATUS.ARCHIVED
          : GIFT_CAMPAIGN_STATUS.PUBLISHED;

      await onUpdateGiftCampaign({
        giftCampaignId: giftCampaign.publicId,
        giftCampaign: { ...giftCampaign, status: updatedStatus },
      });
    }

    setGiftCampaign(null);
    toggleModalVisibility(false);
  };

  const renderActions = (giftCampaign: IGiftCampaign) => {
    return (
      <Space className={styles.actionsLinksWrapper} split={<Divider type="vertical" />} size={2}>
        {giftCampaign.status === GIFT_CAMPAIGN_STATUS.CREATED && (
          <Link onClick={() => showModal(giftCampaign)}>{t('general.publish')}</Link>
        )}
        {giftCampaign.status === GIFT_CAMPAIGN_STATUS.PUBLISHED && (
          <Link onClick={() => showModal(giftCampaign)}>{t('general.unpublish')}</Link>
        )}
        {giftCampaign.status !== GIFT_CAMPAIGN_STATUS.ARCHIVED && (
          <Link onClick={() => onOpenGiftCampaign(giftCampaign)}>{t('general.buttons.edit')}</Link>
        )}
        {giftCampaign.status === GIFT_CAMPAIGN_STATUS.PUBLISHED && (
          <Link onClick={() => handleOpenGiftCampaignPage(giftCampaign)}>{t('contracts.view')}</Link>
        )}
      </Space>
    );
  };

  const handlePageChange = useCallback(async (currentPagination: TablePaginationConfig) => {
    const { current, pageSize } = currentPagination;
    const pagination = {
      page: current || 1,
      limit: pageSize || 10,
    };

    onChange(pagination);
  }, []);

  const tableColumns: ColumnsType<IGiftCampaign> = [
    {
      title: t('general.other.name'),
      dataIndex: 'name',
      width: COLUMN_WIDTH,
    },
    {
      title: t('general.created_at'),
      dataIndex: 'createdAt',
      render: (createdAt: IGiftCampaign['createdAt']) => <div>{DATE_FORMATTER.toUiDateTime(createdAt)}</div>,
      sorter: (g1: IGiftCampaign, g2: IGiftCampaign) => moment(g1.createdAt).diff(moment(g2.createdAt), 'seconds'),
      width: COLUMN_WIDTH,
    },
    {
      title: t('general.updated_at'),
      dataIndex: 'updatedAt',
      render: (updatedAt: IGiftCampaign['updatedAt']) => <div>{DATE_FORMATTER.toUiDateTime(updatedAt)}</div>,
      sorter: (g1: IGiftCampaign, g2: IGiftCampaign) => moment(g1.updatedAt).diff(moment(g2.updatedAt), 'seconds'),
      width: COLUMN_WIDTH,
    },
    {
      ...(giftCampaigns?.[0]?.status !== GIFT_CAMPAIGN_STATUS.ARCHIVED && {
        title: <div className={styles.actionsLinksTitle}>{t('general.actions')}</div>,
        key: 'actions',
        render: (giftCampaign: IGiftCampaign) => {
          return renderActions(giftCampaign);
        },
        width: COLUMN_WIDTH,
      }),
    },
  ];

  const handleSearchTermChange = useCallback(
    (term: string) => {
      const filteredGiftCampaigns = giftCampaigns.filter((gc: IGiftCampaign) =>
        gc.name.toLowerCase().includes(term.toLowerCase()),
      );
      setFilteredGC(filteredGiftCampaigns);
    },
    [giftCampaigns],
  );

  const showModal = (giftCampaign: IGiftCampaign) => {
    toggleModalVisibility(true);
    setGiftCampaign(giftCampaign);
  };

  const handleCancel = () => {
    toggleModalVisibility(false);
  };

  return (
    <div data-testid="GiftCampaignsList" className={styles.root}>
      <SearchInput
        onSearch={handleSearchTermChange}
        placeholder={t('general.search_for_a_title')}
        className={styles.searchInput}
      />

      <Table
        loading={isLoading}
        columns={tableColumns}
        dataSource={filteredGC}
        pagination={{
          current: pagination?.page,
          total: pagination?.total,
          pageSize: pagination?.limit,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        onChange={handlePageChange}
        rowKey="publicId"
        scroll={{ y: window.innerHeight - 320 }}
      />
      <Modal
        open={isModalVisible}
        title={t('general.confirm')}
        onOk={handleUpdateGiftCampaign}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t('link.cancel')}
          </Button>,
          <Button key="submit" type="primary" onClick={handleUpdateGiftCampaign}>
            {giftCampaign?.status === GIFT_CAMPAIGN_STATUS.PUBLISHED ? t('general.unpublish') : t('general.publish')}
          </Button>,
        ]}
      >
        <p>{`Are you sure you want to ${
          giftCampaign?.status === GIFT_CAMPAIGN_STATUS.PUBLISHED ? 'unpublish' : 'publish'
        } ${giftCampaign?.name} ?`}</p>
      </Modal>
    </div>
  );
};

export default GiftCampaignsList;
