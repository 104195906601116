// Generated with util/create-component.js
import React, { useContext, useEffect, useState, useRef, MouseEvent, KeyboardEvent } from 'react';
import { notification, Tabs, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import type { TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { PropertySettingsActionsContext, PropertySettingsDataContext } from 'containers/data_context';

import GiftCardsStoreSettingsForm from 'components/gift_cards_store_settings_form/gift_cards_store_settings_form';
import { IStoreSettings } from 'components/gift_cards_store_settings_form/gift_cards_store_settings_form.types';

import styles from './gift_cards_store_settings_page.module.scss';

const loadingIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;
const defaultStoreSettings = {
  bannerParagraph: '',
  bannerTitle: '',
  cornerStyle: 'rounded',
  tagColor: '#0F1015',
  termsAndConditions: '',
  bookingTermsAndConditions: '',
  textFont: '',
  titleFont: '',
  slugName: '',
  name: '',
  allowCancellation: false,
  cancellationDeadlineDays: 1,
  status: 'active',
  gaTrackingId: '',
  purchaseConfirmationEmail: '',
  allowPickupAtHotel: false,
  allowPickupAtHotelInfo: '',
};

const GiftCardsStoreSettingsPage: React.FC = () => {
  const { t } = useTranslation();
  const newTabIndex = useRef(0);
  const propertySettingsData = useContext(PropertySettingsDataContext);
  const [activeKey, setActiveKey] = useState<string>();
  const [items, setItems] = useState<TabsProps['items']>([]);

  const { loadSuppliersList, loadPropertyGiftCardsStores, createPropertyGiftCardsStore, updatePropertyGiftCardsStore } =
    useContext(PropertySettingsActionsContext);

  const {
    propertiesList: { data: properties },
    selectedProperty,
    isUpdatingGiftCardsStoreSettings,
    propertyGiftCardsStores: { data: propertyGiftCardsStoresData, isLoading: isLoadingGiftCardsStores },
  } = propertySettingsData;

  useEffect(() => {
    async function initApp() {
      if (selectedProperty) {
        try {
          await loadPropertyGiftCardsStores(selectedProperty);
        } catch (e) {
          console.error('error', e);
        }
      }
    }
    initApp();
  }, [loadPropertyGiftCardsStores, selectedProperty]);

  useEffect(() => {
    const items = getItems();
    setItems(items);
    setActiveKey(items[0].key);
  }, [propertyGiftCardsStoresData, selectedProperty, properties]);

  const getItems = () => {
    if (propertyGiftCardsStoresData === null || propertyGiftCardsStoresData?.length === 0) {
      return [
        {
          label: t('gift_card.store_default'),
          children: (
            <GiftCardsStoreSettingsForm
              storeSettings={defaultStoreSettings}
              isUpdatingStoreSettings={isUpdatingGiftCardsStoreSettings}
              onUpdateStoreSettings={handleUpdateStoreSettings}
            />
          ),
          key: '1',
          closable: false,
        },
      ];
    } else {
      return (
        propertyGiftCardsStoresData &&
        propertyGiftCardsStoresData?.map((store: IStoreSettings) => {
          const settings = formatStorySettings(store);
          return {
            label: store.name,
            children: (
              <GiftCardsStoreSettingsForm
                storeSettings={settings}
                isUpdatingStoreSettings={isUpdatingGiftCardsStoreSettings}
                onUpdateStoreSettings={handleUpdateStoreSettings}
              />
            ),
            key: `${store.id}`,
            closable: false,
          };
        })
      );
    }
  };

  const openNotificationWithIcon = (type: 'success' | 'error' | 'nameTaken') => {
    if (type === 'success') {
      return notification['success']({
        message: t('general.success_message'),
      });
    }
    if (type === 'error') {
      return notification['error']({
        message: t('general.error_message'),
        description: t('general.error_description'),
      });
    }
    if (type === 'nameTaken') {
      return notification['error']({
        message: t('gift_card.slug_taken'),
        description: t('gift_card.slug_taken_description'),
      });
    }
  };

  const handleUpdateStoreSettings = async (storeSettings: FormData) => {
    try {
      const storeId = storeSettings.get('store_params[store_setting_attributes][id]');
      const isNewStore = !storeId;

      if (isNewStore) {
        await createPropertyGiftCardsStore({ propertyId: selectedProperty, storeSettings });
      } else {
        await updatePropertyGiftCardsStore({ propertyId: selectedProperty, storeId: storeId, storeSettings });
      }
      openNotificationWithIcon('success');
      loadSuppliersList();
    } catch (e) {
      const error = e as Error;

      if (error.message === 'Validation failed: Name has already been taken') {
        openNotificationWithIcon('nameTaken');
      } else {
        openNotificationWithIcon('error');
      }
    }
  };

  const formatStorySettings = (settings: IStoreSettings) => {
    const formattedSettings = settings ? JSON.parse(JSON.stringify(settings)) : {};
    delete formattedSettings?.photoUrl;
    if (settings?.photoUrl) {
      formattedSettings.photo = {
        name: 'bannerPhoto.png',
        url: settings.photoUrl,
      };
    }
    return formattedSettings;
  };

  const onTabChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey);
  };

  const addStore = () => {
    const newActiveKeyCount = newTabIndex.current++;
    const newActiveKey = `newTab${newActiveKeyCount}`;
    const newPanes = items?.length ? [...items] : [];
    newPanes.push({
      label: t('gift_card.store_new'),
      children: (
        <GiftCardsStoreSettingsForm
          storeSettings={defaultStoreSettings}
          isUpdatingStoreSettings={isUpdatingGiftCardsStoreSettings}
          onUpdateStoreSettings={handleUpdateStoreSettings}
        />
      ),
      key: newActiveKey,
      closable: true,
    });
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };

  const removeTab = (targetKey: string | MouseEvent<Element> | KeyboardEvent<Element>) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items?.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = items?.filter(item => item.key !== targetKey);
    if (newPanes?.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };
  const onEdit = (targetKey: string | MouseEvent<Element> | KeyboardEvent<Element>, action: 'add' | 'remove') => {
    if (action === 'add') {
      addStore();
    } else {
      removeTab(targetKey);
    }
  };

  if (isLoadingGiftCardsStores) {
    return (
      <div className={styles.loading_container} data-testid="LoadingSpinner">
        <Spin indicator={loadingIcon} />
      </div>
    );
  }

  return (
    <div data-testid="GiftCardsStoreSettingsPage" className={styles.root}>
      <Tabs type="editable-card" items={items} activeKey={activeKey} onChange={onTabChange} onEdit={onEdit} />
    </div>
  );
};

export default GiftCardsStoreSettingsPage;
