import { CANCELLATION_POLICY_LOGIC } from 'constants/cancellation_policies';
import moment from 'moment';

const canBeModified = (booking, unit) => {
  const cancellationPolicy = unit ? unit.cancellationPolicy : booking.cancellationPolicy;

  if (!cancellationPolicy) return true;
  if (cancellationPolicy?.cancellationPolicyLogic === CANCELLATION_POLICY_LOGIC.FREE) return true;
  if (cancellationPolicy?.cancellationPolicyLogic === CANCELLATION_POLICY_LOGIC.NON_REFUNDABLE) return false;

  const now = moment();
  const bookingArrivalDatetime = booking.arrivalTime ? (booking.arrivalDate + ' ' + booking.arrivalTime) : booking.arrivalDate;
  const deadline = moment(bookingArrivalDatetime).subtract(cancellationPolicy.cancellationPolicyDeadline, cancellationPolicy.cancellationPolicyDeadlineType);
  
  return now.isBefore(deadline);
};

export default {
  canBeModified,
};
