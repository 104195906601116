import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import Alert from 'components/alert';
import { ICancellationPolicy } from 'components/cancellation_policy_form/cancellation_policy_form.types';

import { DATE_UI_DAY_FORMAT, DATE_UI_MONTH_FORMAT, DATE_UI_YEAR_FORMAT } from 'constants/date_formats';
import { ICancellationPolicyProps } from './cancellation_policy.types';

const DEFAULT_CHECKIN_TIME = '00:00';

const buildNonRefundablePolicyMessage = (t: (par: string) => string) => {
  return t('cancellation_policies.types.non_refundable');
};

const buildDeadlineBasedPolicyMessage = (
  t: (par: string, params?: { [key: string]: string }) => string,
  props: ICancellationPolicyProps,
) => {
  const { cancellationPolicy, checkinDate, propertyPolicy } = props;

  if (!checkinDate || !cancellationPolicy) return null;

  const { cancellationPolicyDeadline, cancellationPolicyDeadlineType } = cancellationPolicy;
  const { checkinFromTime = DEFAULT_CHECKIN_TIME } = propertyPolicy ?? {};
  const [hour, minute] = checkinFromTime.split(':');

  const deadlineDate = checkinDate
    .clone()
    .set({ hour: Number(hour), minute: Number(minute), second: 0, millisecond: 0 })
    .subtract(cancellationPolicyDeadline, cancellationPolicyDeadlineType);

  if (deadlineDate.diff(moment()) < 0) return buildNonRefundablePolicyMessage(t);

  const deadlineMonthDay = deadlineDate.format(DATE_UI_DAY_FORMAT);
  const deadlineMonth = deadlineDate.format(DATE_UI_MONTH_FORMAT);
  const deadlineYear = deadlineDate.format(DATE_UI_YEAR_FORMAT);
  const deadlineDay = `${deadlineMonthDay} ${t(`general.months.${deadlineMonth}`)} ${deadlineYear}`;
  const deadlineHour = deadlineDate.clone().subtract(1, 'minute').format('HH:mm');

  return t('cancellation_policies.types.deadline', { deadlineDay, deadlineHour });
};

const buildFreePolicyMessage = (t: (par: string) => string) => {
  return t('cancellation_policies.types.free');
};

const getPolicyPresentation = (t: (par: string) => string, props: ICancellationPolicyProps) => {
  const { cancellationPolicy } = props;

  const policyPresentationBuilders = {
    deadline: buildDeadlineBasedPolicyMessage,
    non_refundable: buildNonRefundablePolicyMessage,
    free: buildFreePolicyMessage,
  };

  if (!cancellationPolicy?.cancellationPolicyLogic) return;

  const policyHandler = policyPresentationBuilders[cancellationPolicy.cancellationPolicyLogic];
  return policyHandler(t, props);
};

const getAlertVariant = (cancellationPolicy: ICancellationPolicy) => {
  const { cancellationPolicyLogic } = cancellationPolicy;

  if (cancellationPolicyLogic === 'non_refundable') return 'info';

  return 'success';
};

const CancellationPolicy: React.FC<ICancellationPolicyProps> = props => {
  const { cancellationPolicy } = props;
  const { t } = useTranslation();

  if (!cancellationPolicy) return null;

  return <Alert text={getPolicyPresentation(t, props) || ''} variant={getAlertVariant(cancellationPolicy)} />;
};

export default CancellationPolicy;
