// Generated with util/create-component.js
import React, { useEffect, useState, useCallback } from 'react';
import { Table, Space, Divider, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import countries from 'constants/countries';

import { IContractsDiscoverTableProps, IResellerContractPropertyData } from './contracts_discover_table.types';
import styles from './contracts_discover_table.module.scss';

const { Link } = Typography;
const COLUMN_WIDTH = 150;

const ContractsDiscoverTable: React.FC<IContractsDiscoverTableProps> = ({
  tableData,
  isLoading,
  isReseller,
  onPageChange,
  onCreateContract,
  onRequestContract,
  onShowResellerDetails,
}) => {
  const [columns, setColumns] = useState<ColumnsType<IResellerContractPropertyData>>([]);
  const { t } = useTranslation();

  const renderActions = useCallback(
    record => {
      const contractStatus = record?.contract?.status || record?.contract;
      return (
        <Space className={styles.actionsLinksWrapper} split={<Divider type="vertical" />} size={2}>
          {isReseller && contractStatus === 'No Contract' && (
            <Link onClick={() => onRequestContract(record)}>{t('link.request')}</Link>
          )}
          {(contractStatus.includes('Open Contract') || (!isReseller && !contractStatus.includes('x Contracts'))) && (
            <Link onClick={() => onCreateContract(record)}>{t('link.create')}</Link>
          )}
          {!isReseller && <Link onClick={() => onShowResellerDetails(record)}>{t('contracts.view')}</Link>}
        </Space>
      );
    },
    [onShowResellerDetails, onCreateContract, onRequestContract, isReseller],
  );

  useEffect(() => {
    const columns: ColumnsType<IResellerContractPropertyData> = [
      {
        title: t('contracts.supplier'),
        dataIndex: 'name',
        key: 'name',
        width: COLUMN_WIDTH,
      },
      {
        title: t('table.country'),
        dataIndex: 'country',
        key: 'country',
        render: country => countries.find(c => c.value === country)?.label,
        width: COLUMN_WIDTH,
      },
      {
        title: t('table.city'),
        dataIndex: 'city',
        key: 'city',
        width: COLUMN_WIDTH,
      },
      {
        title: t('table.address'),
        dataIndex: isReseller ? 'address' : 'address_1',
        key: 'address',
        width: COLUMN_WIDTH,
      },
      {
        title: t('table.contract'),
        dataIndex: 'contract',
        key: 'contract',
        render: contract => (isReseller ? contract : contract.status),
        width: COLUMN_WIDTH,
      },
      {
        title: <div className={styles.actionsLinksTitle}>{t('general.actions')}</div>,
        key: 'actions',
        render: renderActions,
        width: COLUMN_WIDTH,
      },
    ];

    if (!isReseller) {
      columns[0].title = t('contracts.reseller');
    }

    setColumns(columns);
  }, [isReseller, onCreateContract, onRequestContract, onShowResellerDetails]);

  return (
    <div data-testid="ContractsDiscoverTable" className={styles.root}>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={tableData?.data}
        pagination={{
          current: tableData?.meta?.page,
          total: tableData?.meta?.total,
          pageSize: tableData?.meta?.limit,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        onChange={onPageChange}
        scroll={{ y: window.innerHeight - 320 }}
        rowKey="id"
      />
    </div>
  );
};

export default ContractsDiscoverTable;
