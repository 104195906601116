// Generated with util/create-component.js
import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Row, Col, Tooltip, Collapse, Button, Popconfirm, notification } from 'antd';
import { CalendarOutlined, DeleteOutlined } from '@ant-design/icons';

import isAccommodationProperty from 'utils/is_accommodation_property';
import dateFormatter from 'utils/date_formatter';
import { BOOKING_STATUS } from 'constants/booking_statuses';
import EMPTY_GUEST from 'constants/empty_guest';
import BookingGuests from 'components/booking_guests/booking_guests';
import { IBookingGuest, IBookingUnit } from 'components/bookings_list/bookings_list.types';
import EditableBookingNotes from 'components/editable_booking_notes/editable_booking_notes';

import BookingStatus from 'components/shared/booking_status/booking_status';
import ResellerInfo from 'components/shared/reseller_info/reseller_info';
import CheckinDetails from '../shared/checkin_details/checkin_details';
import BookingGuarantee from '../shared/booking_guarantee/booking_guarantee';
import BookingNotes from '../shared/booking_notes/booking_notes';
import BookingExpenses from '../shared/booking_expenses/booking_expenses';

import { IBookingDetailsProps, IBookingSpecialRequestParams } from './booking_details.types';
import styles from './booking_details.module.scss';
import cancellationPolicies from 'utils/cancellation_policies/cancellation_policies';

const { Panel } = Collapse;

const BookingDetails: React.FC<IBookingDetailsProps> = ({
  booking,
  onCancelBooking,
  onUpdateBookingGuests,
  onUpdateBookingSpecialRequest,
  onDeleteBookingUnits,
  isReseller,
}) => {
  const { t } = useTranslation();

  const isAccommodationBooking = useMemo(() => isAccommodationProperty(booking.propertyType), [booking.propertyType]);

  const initialBookingNotes = useMemo(
    () => ({
      specialRequest: booking.specialRequest,
      reservationName: booking.reservationName,
      externalReferenceId: booking.externalReferenceId,
    }),
    [booking],
  );

  const unitsGuests = useMemo(() => {
    booking?.units?.map((unit: IBookingUnit, index: number) => {
      unit.ordinal = index;
      unit.deleted = false;
    });

    const booking_units = isAccommodationBooking
      ? booking?.units?.map(unit => unit.guests)
      : booking?.units?.map(unit => unit.drivers);

    return booking_units;
  }, [booking.units]);

  const [isCancelingBooking, setIsCancellingBooking] = useState<boolean>(false);
  const [bookingUnits, setBookingUnits] = useState<IBookingUnit[]>(booking.units);
  const [bookingGuests, setBookingGuests] = useState(unitsGuests);
  const [bookingNotes, setBookingNotes] = useState(initialBookingNotes);
  const [deletedUnits, setDeletedUnits] = useState<number[]>([]);
  const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false);

  const handleCancelBooking = async () => {
    try {
      setIsCancellingBooking(true);
      await onCancelBooking(booking);
    } catch (e) {
      console.log(e);
    } finally {
      setIsCancellingBooking(false);
    }
  };

  const unitOccupants = (unit: IBookingUnit) => {
    return isAccommodationBooking ? unit.guests : unit.drivers;
  };

  const isCancelDisabled = useMemo(() => {
    if (!isReseller) return true;
    if (booking.status === BOOKING_STATUS.CANCELLED) return true;
    if (moment().diff(moment(booking.arrivalDate)) > 0) return true;
    if (moment().diff(moment(booking.arrivalDate)) < 0 && !booking.cancellationPolicy) return false;

    return !cancellationPolicies.canBeModified(booking, null);
  }, [booking, isReseller]);

  const openNotificationWithIcon = (type: 'success' | 'error') => {
    if (type === 'success') {
      return notification['success']({
        message: t('general.success_message'),
      });
    } else {
      return notification['error']({
        message: t('general.error_message'),
        description: t('general.error_description'),
      });
    }
  };

  const handleSaveChanges = async () => {
    if (!hasChanges) return;

    try {
      setIsSavingChanges(true);

      if (hasDeletedUnits) {
        const deletedUnitsParams = {
          deleteUnitIds: deletedUnits,
          propertyId: booking.propertyChannelId,
          bookingId: booking.reservationId,
          propertyType: booking.propertyType,
        };
        await onDeleteBookingUnits(deletedUnitsParams);
      }

      if (hasUpdatedUnits) {
        const occupants = (bu: IBookingUnit): Array<IBookingGuest> => (isAccommodationBooking ? bu.guests : bu.drivers);

        const units = bookingUnits
          .filter(bu => !bu.deleted)
          .map(bu => ({
            id: bu.id,
            guests: occupants(bu).filter(guest => JSON.stringify(guest) !== JSON.stringify(EMPTY_GUEST)),
            ratePlanCode: bu.ratePlanCode,
            unitTypeCode: bu.unitTypeCode,
          }));

        const updatedBooking = {
          units,
          propertyId: booking.propertyChannelId,
          bookingId: booking.reservationId,
          propertyType: booking.propertyType,
        };

        await onUpdateBookingGuests(updatedBooking);
      }

      if (hasNotesChanges) {
        const updatedBooking = {
          ...bookingNotes,
          propertyId: booking.propertyChannelId,
          bookingId: booking.reservationId,
          propertyType: booking.propertyType,
        };

        await onUpdateBookingSpecialRequest(updatedBooking);
      }

      openNotificationWithIcon('success');
    } catch (e) {
      console.error(e);
      openNotificationWithIcon('error');
    } finally {
      setIsSavingChanges(false);
    }
  };

  const handleResetChanges = () => {
    booking.units.map((unit: IBookingUnit) => {
      unit.deleted = false;
      unit;
    });

    setBookingUnits(booking.units);
    setBookingGuests(unitsGuests);
    setBookingNotes(initialBookingNotes);
    setDeletedUnits([]);
  };

  const handleEditBookingNotes = (notes: IBookingSpecialRequestParams) => {
    setBookingNotes(notes);
  };

  const handleUpdateBookingGuests = (occupants: IBookingGuest[], unitIndex: number) => {
    const updatedGuests = bookingGuests.slice();
    const updatedUnits = bookingUnits.slice();

    updatedGuests[unitIndex] = occupants;
    if (isAccommodationBooking) {
      updatedUnits[unitIndex].guests = occupants;
    } else {
      updatedUnits[unitIndex].drivers = occupants;
    }

    setBookingGuests(updatedGuests);
    setBookingUnits(updatedUnits);
  };

  const handleDeleteBookingRoom = (unitOrdinal: number) => {
    const currentUnits = bookingUnits.slice();
    const index = currentUnits.findIndex((unit: IBookingUnit) => unit.ordinal === unitOrdinal);
    currentUnits[index].deleted = true;
    deletedUnits.push(currentUnits[index].id);

    setBookingUnits(currentUnits);
    setDeletedUnits(deletedUnits);
  };

  const renderGuests = (unit: IBookingUnit, index: number) => {
    if (isReseller) {
      const maxOccupancy = Number(unit?.occupancy?.adults) + Number(unit?.occupancy?.children);
      return (
        <Row>
          <BookingGuests
            maxOccupancy={maxOccupancy}
            guests={bookingGuests[index]}
            unitTitle={unit.title}
            unitIndex={index}
            onUpdateBookingGuests={handleUpdateBookingGuests}
            propertyType={booking.propertyType}
            canBeModified={
              !isCancelDisabled && cancellationPolicies.canBeModified(booking, unit) && isAccommodationBooking
            }
          />
        </Row>
      );
    }

    if (!isReseller && unitOccupants(unit)?.length === 0) {
      return <Row>{t('guests.no_info_available')}</Row>;
    }

    if (!isReseller && unitOccupants(unit)?.length > 0) {
      return (
        <Row>
          {unitOccupants(unit)?.map((guest: IBookingGuest, index: number) => (
            <Col span={24} key={`guest-${index}`}>{`${guest?.name} ${guest?.surname}`}</Col>
          ))}
        </Row>
      );
    }
  };

  const hasDeletedUnits = useMemo(() => {
    return deletedUnits.length > 0;
  }, [bookingUnits, bookingGuests]);

  const hasUpdatedUnits = useMemo(() => {
    const updatedGuests = bookingGuests?.map(bg =>
      bg?.filter(guest => JSON.stringify(guest) !== JSON.stringify(EMPTY_GUEST)),
    );

    return JSON.stringify(updatedGuests) !== JSON.stringify(unitsGuests);
  }, [bookingGuests, unitsGuests, bookingUnits]);

  const hasNotesChanges = useMemo(() => {
    return JSON.stringify(bookingNotes) !== JSON.stringify(initialBookingNotes);
  }, [bookingNotes, booking]);

  const hasChanges = useMemo(() => {
    return hasDeletedUnits || hasUpdatedUnits || hasNotesChanges;
  }, [bookingGuests, unitsGuests, bookingUnits, bookingNotes]);

  const defaultExpandedUnits = useMemo(() => {
    let maxUnitIndex = booking?.units?.length - 1;

    const activeKeys = [];
    while (0 <= maxUnitIndex) {
      activeKeys.push(`unit-details-${maxUnitIndex}`);
      maxUnitIndex--;
    }

    return activeKeys;
  }, [booking.units]);

  const multipleUnitsBooked = () => {
    return bookingUnits.filter(br => !br.deleted).length > 1;
  };

  return (
    <div data-testid="BookingDetails" className={styles.root}>
      <BookingStatus booking={booking} />
      <CheckinDetails booking={booking} />
      <ResellerInfo booking={booking} />

      <>
        <legend>{t(`bookings_list_${booking.propertyType}.units`)}</legend>
        <Collapse ghost data-testid="RoomDetails" defaultActiveKey={defaultExpandedUnits}>
          {bookingUnits
            ?.filter(br => !br.deleted)
            ?.map((unit: IBookingUnit, index: number) => (
              <Panel
                collapsible="icon"
                header={
                  <Row>
                    <Col span={23}>
                      <Row>{unit.title && <div className={styles.unitTitle}>{unit.title}</div>}</Row>
                      <Row className={styles.unitTitleFooter}>
                        <CalendarOutlined className={styles.calendarOutlined} />
                        {`${dateFormatter.toUIDateWithDay(booking.arrivalDate)} - ${dateFormatter.toUIDateWithDay(
                          booking.departureDate,
                        )}`}
                      </Row>
                    </Col>
                    <Col>
                      {!isCancelDisabled &&
                        isAccommodationBooking &&
                        cancellationPolicies.canBeModified(booking, unit) &&
                        multipleUnitsBooked() && (
                          <Tooltip title={t('general.delete')}>
                            <DeleteOutlined
                              onClick={() => handleDeleteBookingRoom(unit.ordinal || index)}
                              data-testid="DeleteRoom"
                            />
                          </Tooltip>
                        )}
                    </Col>
                  </Row>
                }
                key={`unit-details-${unit.ordinal || index}`}
              >
                {unit.roomRateTitle && (
                  <Row>
                    <Col span={7} className={styles.label}>
                      {t('booking.rate_plan')}:
                    </Col>
                    <Col span={16} offset={1}>
                      {unit.roomRateTitle}
                    </Col>
                  </Row>
                )}

                {isAccommodationBooking && (
                  <Row>
                    <Col span={7} className={styles.label}>
                      {t('general.occupancy')}:
                    </Col>
                    <Col span={16} offset={1}>
                      <Row>
                        <Col span={24}>
                          {t('hotel_page.adults_label')}: {unit.occupancy.adults}
                        </Col>
                        <Col span={24}>
                          {t('hotel_page.children_label')}: {unit.occupancy.children}
                        </Col>
                        <Col span={24}>
                          {t('general.infants')}: {unit.occupancy.infants}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col span={7} className={styles.label}>
                    {t(`hotel_page_${booking.propertyType}.occupants`)}:
                  </Col>
                  <Col sm={16} offset={1} xs={24}>
                    {renderGuests(unit, unit.ordinal || index)}
                  </Col>
                  <Col span={24}></Col>
                </Row>
              </Panel>
            ))}
        </Collapse>
      </>

      <BookingGuarantee booking={booking} />
      {isAccommodationBooking ? (
        !isReseller ? (
          <BookingNotes booking={booking} />
        ) : (
          <EditableBookingNotes booking={booking} onEdit={handleEditBookingNotes} />
        )
      ) : null}
      <BookingExpenses booking={booking} />

      <div className={styles.footer}>
        {hasChanges && (
          <div className={styles.buttonsContainer}>
            <Button aria-label={t('link.cancel')} className={styles.cancelButton} onClick={handleResetChanges}>
              {t('link.cancel')}
            </Button>
            <Button
              loading={isSavingChanges}
              aria-label={t('link.save_changes')}
              disabled={isSavingChanges}
              className={styles.saveButton}
              type="primary"
              onClick={handleSaveChanges}
            >
              {t('link.save_changes')}
            </Button>
          </div>
        )}

        {!hasChanges && isReseller && (
          <Tooltip title={isCancelDisabled ? t('booking.expired') : t('booking.cancel')}>
            <Popconfirm
              title={
                <>
                  <p>{t('general.confirm')}</p>
                  <p>{t('bookings_list.cancel_reservation_explanation')}</p>
                </>
              }
              cancelText={t('link.cancel')}
              okText={t('link.yes')}
              placement="bottomRight"
              trigger="click"
              onConfirm={handleCancelBooking}
              disabled={isCancelDisabled || isCancelingBooking}
            >
              <Button
                loading={isCancelingBooking}
                aria-label={t('booking.cancel')}
                className={`${isCancelDisabled ? styles.disabledBookingButton : styles.cancelBookingButton}`}
              >
                {t('booking.cancel')}
              </Button>
            </Popconfirm>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default BookingDetails;
