import React, { useContext, useEffect, useState } from 'react';
import { Form, Input, Select, Row, Col, Button, notification, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import currencies from 'world-currencies';
import { useTranslation } from 'react-i18next';

import Map from 'components/map_section/map';
import PropertyPhotoGallery from 'components/property_photo_gallery/property_photo_gallery';
import { PropertySettingsDataContext, PropertySettingsActionsContext } from 'containers/data_context';

import countries from 'constants/countries';
import timezones from 'constants/timezones';
import propertyTypeOptions from 'constants/property_types';

import styles from './property_details.module.scss';
import { IPropertyDetailsProps } from './property_details.types';
import { IPhoto } from 'components/property_photo_details/property_photo_details.types';

const { TextArea } = Input;
const { OptGroup, Option } = Select;

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
};

const loadingIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

const PropertyDetails: React.FC<IPropertyDetailsProps> = () => {
  const { t } = useTranslation();

  const { loadPropertySettings, loadPropertyFacilities, updatePropertySettings } =
    useContext(PropertySettingsActionsContext);

  const propertySettingsData = useContext(PropertySettingsDataContext);

  const {
    propertyDetails: { data: propertySettings, isLoading },
    propertyFacilities,
    isUpdatingDetails,
    selectedProperty,
  } = propertySettingsData;

  const getSortedPhotos = (photos: IPhoto[]) => {
    const sortedPhotos = photos
      ?.filter((p: IPhoto) => !p.isRemoved)
      ?.sort((p1: IPhoto, p2: IPhoto) => Number(p1.position) - Number(p2.position));
    return sortedPhotos;
  };

  const sortedPhotos = getSortedPhotos(propertySettings?.content?.photos);
  const [photos, setPhotos] = useState<IPhoto[]>(sortedPhotos);
  const [logo, setLogo] = useState<IPhoto[]>([]);

  useEffect(() => {
    if (selectedProperty) {
      form.resetFields();
      loadPropertySettings(selectedProperty);
      if (!propertyFacilities?.data || !Object.keys(propertyFacilities?.data)?.length) {
        loadPropertyFacilities(selectedProperty);
      }
    }
  }, [selectedProperty]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (propertySettings) {
      const newValues = JSON.parse(JSON.stringify(propertySettings));
      form.setFieldsValue({ ...newValues });

      form.setFieldsValue({
        content: {
          ...newValues.content,
          photos: {
            ...getSortedPhotos(newValues.content.photos),
          },
        },
      });
      setPhotos(getSortedPhotos(newValues?.content?.photos));
      setLogo(newValues.logo);
    }
  }, [propertySettings]);

  const openNotificationWithIcon = (type: 'success' | 'error') => {
    if (type === 'success') {
      return notification['success']({
        message: t('property_details.saved_changes_message'),
      });
    } else {
      return notification['error']({
        message: t('general.error_message'),
        description: t('general.error_description'),
      });
    }
  };

  const handleUpdateLogo = (photos: Array<IPhoto>) => {
    const photo = photos.find(p => !p.isRemoved);
    setLogo(photo ? [photo] : []);
    form.setFieldsValue({
      logo: photo ? [photo] : [],
    });
  };

  const handleCoordinatesChange = (coordinates: { lat: string; lng: string }) => {
    form.setFieldsValue({
      latitude: coordinates.lat,
      longitude: coordinates.lng,
    });
  };

  const handleSubmit = async (): Promise<void> => {
    const updatedValues = form.getFieldsValue(true);
    const propertySettings = JSON.parse(JSON.stringify(updatedValues));

    if (propertySettings.logo?.length) {
      const logoUrl = propertySettings.logo?.find((photo: IPhoto) => !photo.isRemoved)?.url || null;
      propertySettings.logoUrl = logoUrl;
    }

    if (updatedValues.facilities?.length) {
      propertySettings.facilities = updatedValues.facilities?.map((f: { value?: string; label?: string }) => {
        if (f.value) return f.value;
        return f;
      });
    }

    propertySettings.content.photos = photos;
    propertySettings.logoUrl = logo?.length ? logo[0].url : null;

    try {
      await updatePropertySettings(propertySettings);
      openNotificationWithIcon('success');
    } catch (e) {
      openNotificationWithIcon('error');
    }
  };

  const currenciesOptions: Array<{ label: string; value: string }> = Object.values(currencies).map(currency => ({
    label: `${currency.name} (${currency.iso.code})`,
    value: currency.iso.code,
  }));

  const validateMessages = {
    required: t('validation_messages.required'),
  };

  if (isLoading || propertyFacilities.isLoading || !selectedProperty) {
    return (
      <div className={styles.loading_details_container} data-testid="LoadingSpinner">
        <Spin indicator={loadingIcon} />
      </div>
    );
  }

  return (
    <div data-testid="PropertyDetails" className={styles.root}>
      <div className={styles.title}>{t('general.property_details_title')}</div>
      <Form
        form={form}
        initialValues={propertySettings}
        onFinish={handleSubmit}
        {...formItemLayout}
        validateMessages={validateMessages}
      >
        <Form.Item name="title" label={t('general.title')} rules={[{ required: true }]}>
          <Input placeholder={t('general.title')} />
        </Form.Item>
        <Form.Item label={t('general.default_currency')} name="currency" rules={[{ required: true }]}>
          <Select
            showSearch
            optionFilterProp="label"
            placeholder={t('general.default_currency')}
            options={currenciesOptions}
          />
        </Form.Item>
        <Form.Item label={t('gift_card.order_number_prefix')} name="orderNumberPrefix">
          <Input placeholder={t('gift_card.order_number_prefix')} />
        </Form.Item>
        <Form.Item label={t('general.property_type')} name="propertyType">
          <Select
            showSearch
            optionFilterProp="label"
            placeholder={t('general.property_type')}
            options={propertyTypeOptions}
          />
        </Form.Item>

        <legend>{t('general.content')}</legend>
        <Form.Item label={t('general.logo')}>
          <PropertyPhotoGallery
            photos={logo}
            onChange={handleUpdateLogo}
            maxNumberOfPhotos={1}
            showDetails={false}
            multiple={false}
          />
        </Form.Item>
        <Form.Item label={t('general.description')} name={['content', 'description']}>
          <TextArea rows={4} placeholder={t('general.description')} />
        </Form.Item>
        <Form.Item label={t('general.important_information')} name={['content', 'importantInformation']}>
          <TextArea rows={4} placeholder={t('general.important_information')} />
        </Form.Item>
        {propertyFacilities?.data && (
          <Form.Item label={t('general.facilities')} name="facilities">
            <Select mode="multiple" allowClear showSearch optionFilterProp="label">
              {Object.keys(propertyFacilities?.data).map((category: string, ind: number) => (
                <OptGroup label={category.replaceAll('_', ' ').toUpperCase()} key={`${category}-${ind}`}>
                  {propertyFacilities?.data?.[category]?.map(
                    (option: { label: string; value: string }, ind: number) => (
                      <Option key={`${option.label}-${ind}`} value={option.value} label={option.label}>
                        {option.label}
                      </Option>
                    ),
                  )}
                </OptGroup>
              ))}
            </Select>
          </Form.Item>
        )}

        <legend>{t('general.contacts')}</legend>
        <Form.Item label={t('login_page.email')} name="email">
          <Input placeholder={t('login_page.email')} />
        </Form.Item>
        <Form.Item label={t('general.phone')} name="phone">
          <Input placeholder={t('general.phone')} />
        </Form.Item>

        <legend>{t('general.location')}</legend>
        <Form.Item label={t('general.country')} name="country">
          <Select showSearch placeholder={t('general.country')} optionFilterProp="label" options={countries} />
        </Form.Item>
        <Form.Item label={t('general.timezone')} name="timezone">
          <Select showSearch placeholder={t('general.timezone')} optionFilterProp="label" options={timezones} />
        </Form.Item>
        <Form.Item label={t('general.zip_code')} name="zipCode">
          <Input placeholder={t('general.zip_code')} />
        </Form.Item>
        <Form.Item label={t('general.state')} name="state">
          <Input placeholder={t('general.state')} />
        </Form.Item>
        <Form.Item label={t('general.city')} name="city">
          <Input placeholder={t('general.city')} />
        </Form.Item>
        <Form.Item label={t('general.address')} name="address">
          <Input placeholder={t('general.address')} />
        </Form.Item>

        <div className={styles.map}>
          <Map
            isMarkerDraggable={true}
            location={{
              latitude: propertySettings?.latitude,
              longitude: propertySettings?.longitude,
            }}
            onCoordinatesChange={handleCoordinatesChange}
          />
        </div>

        <legend>{t('general.photos')}</legend>
        <Form.Item noStyle>
          <PropertyPhotoGallery photos={photos} onChange={setPhotos} />
        </Form.Item>

        <Row gutter={24} justify="end">
          <Col>
            <Form.Item>
              <Button type="default" onClick={() => form.resetFields()} disabled={isUpdatingDetails}>
                {t('link.cancel')}
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isUpdatingDetails}>
                {t('link.save_changes')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PropertyDetails;
