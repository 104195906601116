// Generated with util/create-component.js
import React, { useContext, useEffect, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnsType } from 'antd/lib/table';
import { TablePaginationConfig } from 'antd/es/table/Table';
import { Table, Dropdown, Popconfirm, Tooltip, Drawer, Row, Col, Button, Checkbox, MenuProps } from 'antd';
import { DownOutlined, EditOutlined, UserDeleteOutlined, SettingOutlined, PlusCircleOutlined } from '@ant-design/icons';

import notification from 'utils/notification';
import DRAWER_SIZE from 'constants/drawer_size';

import {
  SupplierUsersDataContext,
  SupplierUsersActionsContext,
  PropertySettingsDataContext,
  AuthenticationDataContext,
} from 'containers/data_context';

import UserForm from 'components/users/user_form/user_form';
import SearchInput from 'components/search_input';

import { IProperty } from 'components/admin/properties/properties_list/properties_list.types';
import { IUser } from 'components/admin/users/users_list/users_list.types';
import ChangeUserPasswordForm from 'components/users/change_user_password_form/change_user_password_form';
import { IPasswordData } from 'components/users/change_user_password_form/change_user_password_form.types';

import styles from './users_list.module.scss';

const UsersList: React.FC = () => {
  const { t } = useTranslation();

  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 50,
  });

  const [editUser, setEditUser] = useState<IUser | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [openedAddUserForm, toggleAddUserForm] = useState(false);
  const [changePasswordUser, setChangePasswordUser] = useState<IUser | null>(null);
  const [openedChangePasswordForm, toggleChangePasswordForm] = useState(false);
  const [isActive, toggleIsActive] = useState(true);

  const { selectedProperty } = useContext(PropertySettingsDataContext);
  const { loadUsers, createUser, updateUser, updateUserStatus } = useContext(SupplierUsersActionsContext);
  const {
    users: { data, isLoading },
    user: { isCreating, isUpdating },
  } = useContext(SupplierUsersDataContext);

  const { user } = useContext(AuthenticationDataContext);

  const properties = user?.reservaHotels?.filter((rh: IProperty) => rh.role === 'manager');

  useEffect(() => {
    loadUsers({
      ...pagination,
      ...(isActive && { isActive }),
      ...(searchTerm && { q: searchTerm }),
      propertyId: selectedProperty,
    });
  }, [loadUsers, isActive, searchTerm, pagination, selectedProperty]);

  const handleCloseEditForm = () => {
    setEditUser(null);
  };

  const handleOpenEditUserForm = (user: IUser) => {
    const userProperties = user.reservaHotels.filter(rh => {
      return !!properties.find((p: IProperty) => p.id === rh.id);
    });

    setEditUser({ ...user, reservaHotels: userProperties });
  };

  const handleToggleAddUserForm = () => {
    toggleAddUserForm(!openedAddUserForm);
  };

  const handleCloseChangePassword = () => {
    setChangePasswordUser(null);
    toggleChangePasswordForm(false);
  };

  const handleOpenChangePassword = (user: IUser) => {
    setChangePasswordUser(user);
    toggleChangePasswordForm(true);
  };

  const handleUserStatus = (user: IUser) => {
    const usersData = {
      id: user.id,
      status: user.status === 'active' ? 'blocked' : 'active',
    };

    updateUserStatus({ user: usersData, propertyId: selectedProperty });
  };

  const handleUpdatePassword = async (passwordData: IPasswordData) => {
    await updateUser({ user: { ...changePasswordUser, ...passwordData }, propertyId: selectedProperty });
  };

  const renderMenu = (user: IUser): MenuProps => {
    const menuItems: MenuProps['items'] = [
      {
        key: 'edit',
        label: t('general.edit'),
        icon: <EditOutlined />,
        onClick: () => handleOpenEditUserForm(user),
      },
      {
        key: user.status === 'active' ? 'Block' : 'Unblock',
        label: (
          <Tooltip title={user.status === 'active' ? t('user.block_user') : t('user.unblock_user')}>
            <Popconfirm
              title={
                <>
                  <p>{t('bookings_list.confirm')}</p>
                  {user.status === 'active' && <p>{`${t('user.block_confirmation')} ${user.name}`}?</p>}
                  {user.status === 'blocked' && <p>{`${t('user.unblock_confirmation')} ${user.name}`}?</p>}
                </>
              }
              cancelText={t('link.cancel')}
              okText={t('link.yes')}
              placement="bottomRight"
              onConfirm={() => handleUserStatus(user)}
            >
              {user.status === 'active' ? t('user.block') : t('user.unblock')}
            </Popconfirm>
          </Tooltip>
        ),
        icon: <UserDeleteOutlined />,
      },
      {
        key: 'changePassword',
        label: t('user.change_password'),
        icon: <SettingOutlined />,
        onClick: () => handleOpenChangePassword(user),
      },
    ];

    return { items: menuItems };
  };

  const renderActionButtons = (user: IUser) => {
    return (
      <Dropdown menu={renderMenu(user)}>
        <div className={styles.actionsLink}>
          <span className={styles.actionLinkText}>{t('general.actions')}</span> <DownOutlined />
        </div>
      </Dropdown>
    );
  };

  const usersListColumns: ColumnsType<IUser> = useMemo(
    () => [
      {
        title: t('general.other.email'),
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: t('general.other.name'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('bookings_list.status_title'),
        dataIndex: 'status',
        key: 'status',
      },
      {
        key: 'actions',
        render: (record: IUser) => renderActionButtons(record),
      },
    ],
    [],
  );

  const handlePageChange = useCallback(async (currentPagination: TablePaginationConfig) => {
    const { current, pageSize } = currentPagination;
    const pagination = {
      page: current || 1,
      perPage: pageSize || 50,
    };

    setPagination(pagination);
  }, []);

  const handleSearchTermChange = useCallback(async (term: string) => {
    setPagination({
      page: 1,
      perPage: 50,
    });

    setSearchTerm(term);
  }, []);

  const handleToggleIsActive = (isActive: boolean) => {
    toggleIsActive(isActive);

    setPagination({
      page: 1,
      perPage: 50,
    });
  };

  const handleSaveUser = async (user: IUser) => {
    try {
      if (user.id) {
        await updateUser({ user, propertyId: selectedProperty });
      } else {
        await createUser({ user, propertyId: selectedProperty });
      }
      notification.withIcon('success', t('general.success_message'));
    } catch (e) {
      console.log(e, 'error');
      notification.withIcon('error', t('general.error_message'), t('general.error_description'));
    }
  };

  return (
    <div data-testid="UsersList" className={styles.root}>
      <Row justify="space-between" align="middle" className={styles.headerContainer} gutter={[16, 16]}>
        <Col lg={17} sm={24} xs={24}>
          <SearchInput onSearch={handleSearchTermChange} placeholder={t('general.search_user')} />
        </Col>
        <Col lg={3} offset={1} sm={24} xs={11}>
          <Checkbox
            className={styles.select_all}
            onChange={e => handleToggleIsActive(e.target.checked)}
            checked={isActive}
          >
            {t('general.only_active')}
          </Checkbox>
        </Col>
        <Col lg={3} sm={24} xs={12}>
          <Button
            onClick={handleToggleAddUserForm}
            type="primary"
            size="large"
            icon={<PlusCircleOutlined />}
            className={styles.createButton}
          >
            {t('general.add_user')}
          </Button>
        </Col>
      </Row>
      <Table
        loading={isLoading}
        scroll={{ y: window.innerHeight - 300 }}
        columns={usersListColumns}
        dataSource={data?.users}
        pagination={{
          current: pagination?.page,
          total: data?.total,
          pageSize: pagination?.perPage,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        onChange={handlePageChange}
        rowKey="id"
      />

      {editUser && (
        <Drawer
          open={!!editUser}
          title={t('general.buttons.edit')}
          width={window.innerWidth > 900 ? DRAWER_SIZE.MEDIUM : window.innerWidth}
          onClose={handleCloseEditForm}
          destroyOnClose={true}
        >
          <UserForm
            user={editUser}
            isSaving={isUpdating}
            onSaveUser={handleSaveUser}
            properties={properties}
            onCancel={handleCloseEditForm}
            isChangeable={user?.id !== editUser?.id}
          />
        </Drawer>
      )}

      <Drawer
        open={openedAddUserForm}
        title={t('general.add_user')}
        width={window.innerWidth > 900 ? DRAWER_SIZE.MEDIUM : window.innerWidth}
        onClose={handleToggleAddUserForm}
        destroyOnClose={true}
      >
        <UserForm
          onCancel={handleToggleAddUserForm}
          isSaving={isCreating}
          onSaveUser={handleSaveUser}
          properties={properties}
          isChangeable={true}
        />
      </Drawer>

      <Drawer
        open={openedChangePasswordForm}
        title={t('user.change_password')}
        width={window.innerWidth > 900 ? DRAWER_SIZE.MEDIUM : window.innerWidth}
        onClose={handleCloseChangePassword}
        destroyOnClose={true}
      >
        <ChangeUserPasswordForm
          isSaving={isUpdating}
          onCancel={handleCloseChangePassword}
          onSave={handleUpdatePassword}
        />
      </Drawer>
    </div>
  );
};

export default UsersList;
