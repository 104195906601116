import React, { useContext, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  UnorderedListOutlined,
  LogoutOutlined,
  SettingOutlined,
  EditOutlined,
  SearchOutlined,
  BranchesOutlined,
  ToolOutlined,
  GiftOutlined,
  BellOutlined,
  KeyOutlined,
} from '@ant-design/icons';
import { Button, Divider, Drawer, Select, Menu, MenuProps, Badge } from 'antd';

import { ReactComponent as Logo } from 'assets/logo.svg';
import buildPath from 'utils/build_path';
import isCarProperty from 'utils/is_car_property';
import routes from 'routing/routes';
import USER_ROLE from 'constants/user_role';

import { PropertySettingsDataContext } from 'containers/data_context';

import LocaleSelectMobile from 'components/locale_select_mobile/locale_select_mobile';

import styles from './nav_mobile.module.scss';
import { INavMobileProps } from './nav_mobile.types';
import { IProperty } from 'components/nav/nav.types';
import { IStoreSettings } from 'components/gift_cards_store_settings_form/gift_cards_store_settings_form.types';
import { DefaultOptionType } from 'antd/lib/select';
type MenuItem = Required<MenuProps>['items'][number];

const NavMobile: React.FC<INavMobileProps> = ({
  user,
  onSwitchProperty,
  onLogout,
  checkLock,
  checkWarning,
  isReseller,
  stripeRedirect,
  stripeNotification,
}) => {
  const [showDrawer, setShowDrawer] = React.useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState('');
  const [isSearchingProperty, toggleSearchingProperty] = useState(false);

  const { t, i18n } = useTranslation();
  const history = useHistory();

  const propertySettingsData = useContext(PropertySettingsDataContext);

  const {
    propertiesList: { data: properties, isLoading },
    selectedProperty,
    propertyGiftCardsStores: { data: giftCardsStores },
  } = propertySettingsData;

  const navbarLocked = useMemo(() => {
    return checkLock(selectedProperty);
  }, [selectedProperty]);

  const navbarWarning = useMemo(() => {
    return checkWarning(selectedProperty);
  }, [selectedProperty]);

  const propertyType = useMemo(() => {
    return properties?.find((p: IProperty) => p.value === selectedProperty)?.propertyType;
  }, [selectedProperty]);

  const leftContent = user?.name || '';
  const carRentalFeatureEnabled = user?.agent?.carRentalFeature;

  const handleOpenGiftCardStore = useCallback(
    (route: string, params: { selectedProperty: string; slugName: string }) => {
      const pagePath = buildPath(`?locale=${i18n.language}`, route, { selectedProperty: params.slugName });
      window.open(pagePath, '_blank');
    },
    [],
  );

  const handleOpenPage = (route: string, params = {}) => {
    const pagePath = buildPath('', route, params);
    setShowDrawer(false);
    history.push(pagePath);
  };

  const handleOpenMainPage = useCallback(() => {
    if (navbarLocked) return;

    handleOpenPage(routes.bookingsPage);
  }, [history, navbarLocked]);

  const handleToggleSearchProperty = (propertyId: string) => {
    toggleSearchingProperty(false);
    onSwitchProperty(propertyId);
    if (checkLock(propertyId)) history.push(routes.bookingsPage);
  };

  const handleFilteringProperty = (input: string, option: DefaultOptionType | undefined) => {
    if (!option || !option?.children) return false;

    return option?.children.toString().toLowerCase().includes(input.toLowerCase());
  };

  const disableGiftCardShop = function () {
    return !propertySettingsData.propertiesList.data?.find((e: IProperty) => e.value === selectedProperty)
      ?.hasPaymentProvider;
  };

  const menu = useMemo(() => {
    const menuItems: MenuProps['items'] = [];

    if (isReseller && carRentalFeatureEnabled) {
      menuItems.push({
        key: 'searchCarsPage',
        label: t('link.cars'),
        icon: <SearchOutlined />,
        onClick: () => handleOpenPage(routes.searchCarsPage),
        disabled: navbarLocked,
      });
    }

    if (!isReseller) {
      menuItems.push({
        key: 'settings',
        label: t('link.settings'),
        icon: <SettingOutlined />,
        onClick: () => handleOpenPage(routes.settingsPagePropertyDetails),
      });
    }

    if (user?.role === USER_ROLE.ADMIN) {
      menuItems.push({
        key: 'admin',
        label: t('link.admin'),
        icon: <SettingOutlined />,
        onClick: () => handleOpenPage(routes.adminPage),
      });
    }

    if (isReseller) {
      menuItems.push({
        key: 'apiKeys',
        label: 'Api Keys',
        icon: <KeyOutlined />,
        onClick: () => handleOpenPage(routes.apiKeysPage),
        disabled: navbarLocked,
      });
    }

    if (user?.role !== USER_ROLE.ADMIN) {
      menuItems.push({
        key: 'billing',
        label: <Badge dot={navbarWarning || navbarLocked || stripeNotification}>{i18n.t('auth.billing')}</Badge>,
        icon: <ToolOutlined />,
        onClick: () => stripeRedirect(),
      });
    }

    menuItems.push({
      key: 'changePassword',
      label: t('auth.change_password'),
      icon: <ToolOutlined />,
      onClick: () => handleOpenPage(routes.changePassword),
    });

    menuItems.push({
      key: 'bookings',
      label: (
        <div onClick={() => handleOpenPage(routes.bookingsPage)}>
          <div>{t('link.bookings')}</div>
        </div>
      ),
      icon: <EditOutlined />,
      onClick: () => handleOpenPage(routes.bookingsPage),
      disabled: isReseller && navbarLocked,
    });

    if (!isReseller) {
      menuItems.push({
        key: 'inventory',
        label: t('link.inventory'),
        icon: <EditOutlined />,
        onClick: () => handleOpenPage(routes.inventoryPage),
        disabled: navbarLocked,
      });

      menuItems.push({
        key: 'orders',
        label: t('general.orders'),
        icon: <GiftOutlined />,
        onClick: () => handleOpenPage(routes.ordersPage),
        disabled: navbarLocked,
      });

      menuItems.push({
        key: 'giftCardOrders',
        label: t('general.gift_cards'),
        icon: <GiftOutlined />,
        onClick: () => handleOpenPage(routes.giftCardPage),
        disabled: navbarLocked,
      });

      menuItems.push({
        key: 'giftCards',
        label: t('general.products'),
        icon: <GiftOutlined />,
        onClick: () => handleOpenPage(routes.productsPage),
        disabled: navbarLocked,
      });

      if (giftCardsStores?.length > 1) {
        const activeStoresItems =
          giftCardsStores
            .filter((gs: IStoreSettings) => gs.status === 'active')
            .map((gs: IStoreSettings) => {
              return {
                key: gs.id,
                label: gs.name,
                onClick: () =>
                  handleOpenGiftCardStore(routes.giftCardStorePage, { selectedProperty, slugName: gs.slugName }),
                disabled: navbarLocked,
              };
            }) || [];
        menuItems.push({
          label: <div>{i18n.t('general.store')}s</div>,
          key: 'sub1',
          icon: <GiftOutlined />,
          children: activeStoresItems,
        } as MenuItem);
      } else {
        menuItems.push({
          key: 'giftCardsStore',
          label: `${t('general.gift_cards')} ${t('general.store')}`,
          icon: <GiftOutlined />,
          disabled: disableGiftCardShop() || navbarLocked,
          onClick: () =>
            handleOpenGiftCardStore(routes.giftCardStorePage, {
              selectedProperty,
              slugName: giftCardsStores[0]?.slugName,
            }),
        });
      }
    }
    if (isCarProperty(propertyType)) {
      menuItems.push({
        key: 'contracts',
        label: t('link.contracts'),
        icon: <BranchesOutlined />,
        onClick: () => handleOpenPage(routes.contractsPage),
        disabled: navbarLocked,
      });
    }

    return <Menu onClick={e => setSelectedTab(e.key)} selectedKeys={[selectedTab]} mode="inline" items={menuItems} />;
  }, [selectedProperty, giftCardsStores, isReseller, user?.role, selectedTab, navbarWarning, navbarLocked]);

  if (!user) return null;

  return (
    <nav className={styles.root} data-testid="NavMobile">
      <Logo className={isReseller ? styles.logo_center : styles.logo_left} onClick={handleOpenMainPage} />
      {selectedProperty && !isReseller && (
        <div className={isSearchingProperty ? styles.search_property_wrapper : ''}>
          <Select
            showSearch
            style={{ width: isSearchingProperty ? '100%' : '180px' }}
            open={isSearchingProperty}
            autoFocus={isSearchingProperty}
            defaultOpen={isSearchingProperty}
            placeholder={t('general.property_title')}
            loading={isLoading}
            defaultValue={selectedProperty}
            optionFilterProp="children"
            filterOption={(input, option) => handleFilteringProperty(input, option)}
            onDropdownVisibleChange={toggleSearchingProperty}
            onFocus={() => toggleSearchingProperty(true)}
            onSelect={() => toggleSearchingProperty(false)}
            dropdownMatchSelectWidth={11}
            onChange={handleToggleSearchProperty}
          >
            {properties?.map((property: IProperty, index: number) => (
              <Select.Option value={property.value} key={`${property}-${index}`}>
                {property.label}
              </Select.Option>
            ))}
          </Select>
        </div>
      )}
      <div className={styles.left_content}>
        <div className={styles.agent_name}>{leftContent}</div>
        <Button
          type="text"
          shape="circle"
          icon={<UnorderedListOutlined />}
          className={styles.nav_button}
          size="large"
          onClick={() => setShowDrawer(true)}
        />
      </div>
      <div className={styles.notification_container}>
        <BellOutlined className={styles.notification} id="beamer-newsfeed-button" />
      </div>
      <Drawer
        title="Reserva"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        width="250px"
        bodyStyle={{ padding: 0 }}
      >
        <div className={styles.language_select}>
          <span>{t('profile_nav.profile.locale')}</span>
          <LocaleSelectMobile />
        </div>
        {menu}
        <Divider />
        <Button onClick={onLogout} icon={<LogoutOutlined />} className={styles.logout_button}>
          {t('navigation.logout')}
        </Button>
      </Drawer>
    </nav>
  );
};

export default NavMobile;
