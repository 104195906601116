import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Table, Button, Typography, message, Input, Select, Space, Drawer, Divider } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/lib/table';

import { BOOKING_STATUS } from 'constants/booking_statuses';
import DRAWER_SIZE from 'constants/drawer_size';
import dateFormatter from 'utils/date_formatter';

import CancellationPolicy from 'components/rates_table/property_unit/rate_plan/rate_plan_policies/cancellation_policy';
import BookingDetails from 'components/booking_details/booking_details';
import { IBookingGuestsParams } from 'components/booking_guests/booking_guests.types';
import { IReseller } from 'components/admin/resellers/resellers_list/resellers_list.types';

import { IBookingsListProps, IBooking, IFilterTableProps, ISearchColumnProps } from './bookings_list.types';
import styles from './bookings_list.module.scss';
import {
  IBookingSpecialRequestParams,
  IDeleteBookingUnitParams,
} from 'components/booking_details/booking_details.types';

const { Link } = Typography;

interface BookingColumnType extends ColumnType<IBooking> {
  visible?: boolean;
}

const COLUMN_WIDTH = 150;
const SMALL_COLUMN_WIDTH = 100;

const BookingsList: React.FC<IBookingsListProps> = ({
  bookings,
  isLoading,
  isReseller,
  pagination,
  resellers,
  filters,
  onChange,
  onCancelBooking,
  onFilterChange,
  onUpdateBookingGuests,
  onUpdateBookingSpecialRequest,
  onDeleteBookingUnits,
}) => {
  const { t } = useTranslation();
  const [bookingsList, setBookingsList] = useState<IBooking[]>(bookings);
  const [booking, toggleBookingDetails] = useState<IBooking | null>(null);
  const [resellerFilter, setResellerFilter] = useState<string[] | []>(filters?.reseller || []);

  useEffect(() => {
    setBookingsList(bookings);
  }, [bookings]);

  useEffect(() => {
    setResellerFilter(filters?.reseller || []);
  }, [filters?.reseller]);

  const getColumnSearchProps = (column: ISearchColumnProps) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: IFilterTableProps) => {
      return (
        <div className={styles.filterDropdownWrapper}>
          <Input
            placeholder={t('link.search')}
            value={selectedKeys[0]}
            autoFocus
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(confirm, column, selectedKeys[0])}
            className={styles.filterDropdownInput}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(confirm, column, selectedKeys[0])}
              icon={<SearchOutlined />}
              size="small"
              className={styles.filterButton}
            >
              {t('link.search')}
            </Button>
            <Button
              onClick={() => handleReset(clearFilters, confirm, column)}
              size="small"
              className={styles.filterButton}
            >
              {t('link.reset')}
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '' }} />,
  });

  const getResellerSearchProps = (column: ISearchColumnProps) => ({
    filterDropdown: ({ confirm, clearFilters }: IFilterTableProps) => {
      return (
        <div className={styles.filterDropdownWrapper}>
          <Select
            className={styles.filterDropdownInput}
            showSearch
            mode="multiple"
            placeholder={t('general.reseller')}
            optionFilterProp="children"
            filterOption={true}
            value={resellerFilter}
            onChange={setResellerFilter}
          >
            {resellers?.map((reseller: IReseller) => (
              <Select.Option key={reseller.id} value={reseller.id}>
                {reseller.name}
              </Select.Option>
            ))}
          </Select>
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(confirm, column, resellerFilter)}
              icon={<SearchOutlined />}
              size="small"
              className={styles.filterButton}
            >
              {t('link.search')}
            </Button>
            <Button
              onClick={() => handleReset(clearFilters, confirm, column)}
              size="small"
              className={styles.filterButton}
            >
              {t('link.reset')}
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '' }} />,
  });

  const handleSearch = (
    confirm: () => void,
    column: ISearchColumnProps,
    value: string | number | boolean | null | string[],
  ) => {
    onFilterChange(column, value);
    confirm();
  };

  const handleReset = (clearFilters: () => void, confirm: () => void, column: string) => {
    clearFilters();
    onFilterChange(column, null);
    confirm();
  };

  const tableColumns: BookingColumnType[] = [
    {
      title: t('hotel_page.checkin_label'),
      dataIndex: 'arrivalDate',
      key: 'arrivalDate',
      sorter: true,
      width: SMALL_COLUMN_WIDTH,
    },
    {
      title: t('hotel_page.checkout_label'),
      dataIndex: 'departureDate',
      key: 'departureDate',
      sorter: true,
      width: SMALL_COLUMN_WIDTH,
    },
    {
      title: t('bookings_list.booked_on_title'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: IBooking['createdAt']) => dateFormatter.toUiShortMonth(createdAt),
      sorter: true,
      defaultSortOrder: 'descend',
      width: SMALL_COLUMN_WIDTH,
    },
    {
      title: t('bookings_list.status_title'),
      key: 'status',
      render: (booking: IBooking) => renderStatus(booking),
      width: COLUMN_WIDTH,
    },
    {
      title: t('bookings_list.price_title'),
      dataIndex: 'price',
      key: 'price',
      width: SMALL_COLUMN_WIDTH,
    },
    {
      title: t('bookings_list.reservation_id_title'),
      dataIndex: 'reservationId',
      key: 'reservationId',
      ...getColumnSearchProps('reservationId'),
      width: COLUMN_WIDTH,
    },
  ];

  if (isReseller) {
    tableColumns.unshift({
      title: t('bookings_list.supplier_title'),
      dataIndex: 'hotelName',
      key: 'hotelName',
      sorter: true,
      width: COLUMN_WIDTH,
    });
  } else {
    tableColumns.unshift({
      title: t('bookings_list.reseller_title'),
      key: 'reseller',
      render: (booking: IBooking) => <div>{booking.resellerName}</div>,
      ...getResellerSearchProps('reseller'),
      width: COLUMN_WIDTH,
      filterMultiple: true,
    });
  }

  tableColumns.push({
    title: t('bookings_list.actions_title'),
    key: 'view',
    render: (booking: IBooking) => (
      <Space className={styles.actionsLinksWrapper} split={<Divider type="vertical" />} size={2}>
        <Link onClick={() => toggleBookingDetails(booking)}>{t('contracts.view')}</Link>
      </Space>
    ),
    width: SMALL_COLUMN_WIDTH,
  });

  const renderStatus = (booking: IBooking) => {
    switch (booking.status) {
      case BOOKING_STATUS.ACTIVE: {
        return (
          <div>
            <div className={styles.activeStatus}>{t('bookings_list.active_status')}</div>
            <div className={styles.cancellationPolicyWrapper}>
              <CancellationPolicy
                cancellationPolicy={booking.cancellationPolicy}
                checkinDate={moment(booking.arrivalDate)}
                propertyPolicy={booking.propertyPolicy}
              />
            </div>
          </div>
        );
      }
      case BOOKING_STATUS.CANCELLED: {
        return <div className={styles.cancelledStatus}>{t('bookings_list.cancelled_status')}</div>;
      }
      case BOOKING_STATUS.MODIFIED: {
        return (
          <div>
            <div className={styles.activeStatus}>{t('bookings_list.modified_status')}</div>
            <div className={styles.cancellationPolicyWrapper}>
              <CancellationPolicy
                cancellationPolicy={booking.cancellationPolicy}
                checkinDate={moment(booking.arrivalDate)}
                propertyPolicy={booking.propertyPolicy}
              />
            </div>
          </div>
        );
      }
    }
  };

  const handleCancelBooking = async (booking: IBooking) => {
    const { id, propertyChannelId, reservationId, propertyType } = booking;

    try {
      const cancelledBooking = await onCancelBooking({ reservationId, propertyChannelId, propertyType });
      if (cancelledBooking) {
        const updatedBookingsList = [...bookingsList];
        const bookingIndex = updatedBookingsList.findIndex(booking => booking.id === id);
        updatedBookingsList[bookingIndex].status = BOOKING_STATUS.CANCELLED;
        setBookingsList(updatedBookingsList);
        toggleBookingDetails(null);
      }
    } catch (error) {
      message.error(t('payment_page.error_modal.text'), 4);
    }
  };

  const handleUpdateBookingGuests = async (bookingDetails: IBookingGuestsParams) => {
    try {
      await onUpdateBookingGuests(bookingDetails);
      toggleBookingDetails(null);
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const handleUpdateBookingSpecialRequest = async (bookingDetails: IBookingSpecialRequestParams) => {
    try {
      await onUpdateBookingSpecialRequest(bookingDetails);
      toggleBookingDetails(null);
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const handleDeleteBookingUnits = async (deletedUnits: IDeleteBookingUnitParams) => {
    try {
      await onDeleteBookingUnits(deletedUnits);
      toggleBookingDetails(null);
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const tableHeight = window.innerHeight + 80 - ((pagination?.total || 0) > 10 ? 320 : 160);
  const drawerWidth =
    window.innerWidth > 900 ? (isReseller ? DRAWER_SIZE.LARGE : DRAWER_SIZE.MEDIUM) : window.innerWidth;

  return (
    <div data-testid="BookingsList" className={styles.root}>
      <Table
        dataSource={bookingsList}
        columns={tableColumns}
        rowKey={record => record.id}
        loading={isLoading}
        scroll={{ x: 'max-content', y: tableHeight }}
        pagination={pagination}
        onChange={onChange}
      />
      <Drawer
        open={!!booking}
        title={t('booking.details')}
        width={drawerWidth}
        onClose={() => toggleBookingDetails(null)}
        destroyOnClose={true}
      >
        {booking && (
          <BookingDetails
            booking={booking}
            isReseller={isReseller}
            onCancelBooking={handleCancelBooking}
            onUpdateBookingGuests={handleUpdateBookingGuests}
            onUpdateBookingSpecialRequest={handleUpdateBookingSpecialRequest}
            onDeleteBookingUnits={handleDeleteBookingUnits}
          />
        )}
      </Drawer>
    </div>
  );
};

export default BookingsList;
