// Generated with util/create-component.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Button, Popconfirm, notification } from 'antd';
import moment from 'moment';

import { BOOKING_STATUS } from 'constants/booking_statuses';
import { IGiftCardBookingDetailsProps } from './gift_card_booking_details.types';
import styles from './gift_card_booking_details.module.scss';
import CheckinDetails from 'components/shared/checkin_details/checkin_details';
import BookingStatus from 'components/shared/booking_status/booking_status';
import BookingContent from 'components/shared/booking_content/booking_content';
import ResellerInfo from '../shared/reseller_info/reseller_info';
import BookingGuarantee from '../shared/booking_guarantee/booking_guarantee';
import BookingNotes from '../shared/booking_notes/booking_notes';
import BookingExpenses from '../shared/booking_expenses/booking_expenses';

const GiftCardBookingDetails: React.FC<IGiftCardBookingDetailsProps> = ({
  booking,
  giftCard,
  isReseller,
  onCancelBooking,
  onMarkGiftCard,
}) => {
  const { t } = useTranslation();

  const [isCancelingBooking, setIsCancellingBooking] = useState<boolean>(false);

  const openNotificationWithIcon = (type: 'success' | 'error') => {
    if (type === 'success') {
      return notification['success']({
        message: t('general.success_message'),
      });
    } else {
      return notification['error']({
        message: t('general.error_message'),
        description: t('general.error_description'),
      });
    }
  };

  const handleCancelBooking = async () => {
    try {
      setIsCancellingBooking(true);
      await onCancelBooking(booking);
      openNotificationWithIcon('success');
    } catch (e) {
      openNotificationWithIcon('error');
    } finally {
      setIsCancellingBooking(false);
    }
  };

  const handleCancelBookingMarkUnused = async () => {
    try {
      setIsCancellingBooking(true);
      await onCancelBooking(booking);
      await onMarkGiftCard(giftCard);
      openNotificationWithIcon('success');
    } catch (e) {
      openNotificationWithIcon('error');
    } finally {
      setIsCancellingBooking(false);
    }
  };

  return (
    <div data-testid="GiftCardBookingDetails" className={styles.root}>
      <BookingStatus booking={booking} />
      <CheckinDetails booking={booking} />
      <ResellerInfo booking={booking} />
      <BookingContent booking={booking} isReseller={isReseller} />
      <BookingGuarantee booking={booking} />
      <BookingNotes booking={booking} />
      <BookingExpenses booking={booking} />

      <div className={styles.footer}>
        {!(booking.status === BOOKING_STATUS.CANCELLED) && moment(booking.arrivalDate).isAfter() && (
          <Tooltip title={t('booking.cancel')}>
            <Popconfirm
              title={
                <>
                  <p>{t('general.confirm')}</p>
                  <p>{t('gift_card.reissue_confirmation')}</p>
                </>
              }
              cancelText={t('link.no')}
              okText={t('link.yes')}
              placement="bottomRight"
              trigger="click"
              onConfirm={handleCancelBookingMarkUnused}
              onCancel={handleCancelBooking}
              disabled={isCancelingBooking}
            >
              <Button
                loading={isCancelingBooking}
                aria-label={t('booking.cancel')}
                disabled={isCancelingBooking}
                className={styles.cancelBookingButton}
              >
                {t('booking.cancel')}
              </Button>
            </Popconfirm>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default GiftCardBookingDetails;
