// Generated with util/create-component.js
import React from 'react';
import { DatePicker, Row, Col } from 'antd';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';

import { DATE_API_FORMAT } from 'constants/date_formats';

import { IGiftCardDatePickerProps } from './gift_card_date_picker.types';
import styles from './gift_card_date_picker.module.scss';

const GiftCardDatePicker: React.FC<IGiftCardDatePickerProps> = ({
  dates,
  maxDays,
  maxDate,
  closedDatesPerRate,
  onDatesChange,
}) => {
  const { t } = useTranslation();

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const checkinRef: React.RefObject<any> = React.createRef();

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const checkoutRef: React.RefObject<any> = React.createRef();

  const handleDateChange = (date: Moment | null, index: number) => {
    checkinRef?.current?.blur();
    checkoutRef?.current?.blur();

    if (!date) {
      onDatesChange([null, null]);
      return;
    }
    if (date && index === 0) {
      const checkoutDate = moment(date).add(maxDays, 'days');
      onDatesChange([date, checkoutDate]);
      return;
    }
    if (date && index === 1 && !dates?.[0]) {
      const checkinDate = moment(date).add(-maxDays, 'days');
      onDatesChange([checkinDate, date]);
      return;
    }
  };

  const isDateClosedForCheckin = (date: Moment) => {
    if (closedDatesPerRate) {
      let isClosedRange = true;

      for (const ratePlanId in closedDatesPerRate) {
        if (ratePlanId === 'type') {
          continue;
        }

        const hasClosedDate = closedDatesPerRate[ratePlanId].closed.find(d => {
          if (d === moment(date).format(DATE_API_FORMAT)) return true;
          return (
            0 <= moment(d, DATE_API_FORMAT).diff(moment(date), 'days') &&
            moment(d, DATE_API_FORMAT).diff(moment(date), 'days') < maxDays - 1
          );
        });

        if (!hasClosedDate) {
          isClosedRange = false;
          break;
        }
      }
      return isClosedRange;
    }

    return false;
  };

  const isDateClosedForCheckout = (date: Moment) => {
    if (closedDatesPerRate) {
      let isClosedRange = true;

      for (const ratePlanId in closedDatesPerRate) {
        if (ratePlanId === 'type') {
          continue;
        }

        const hasClosedDate = closedDatesPerRate[ratePlanId].closed.find(d => {
          const diff = moment(date).diff(moment(d, DATE_API_FORMAT), 'days');
          if (0 < diff && diff < maxDays) return d;
        });

        if (!hasClosedDate) {
          isClosedRange = false;
          break;
        }
      }
      return isClosedRange;
    }

    return false;
  };

  const isPastDate = (date: Moment) => 0 < moment().diff(date, 'days');

  const isNotAvailableDate = (date: Moment) => {
    if (maxDate && maxDate.diff(date, 'days') < 0) {
      return true;
    }

    return false;
  };

  const isCheckinDateDisabled = (date: Moment) => {
    if (isPastDate(date)) return true;

    if (dates?.[0] && dates?.[0].diff(date, 'days') === 0) return true;

    if (isDateClosedForCheckin(date)) return true;

    if (isNotAvailableDate(date)) return true;

    return false;
  };

  const isCheckoutDateDisabled = (date: Moment) => {
    if (isPastDate(date)) return true;

    if (isDateClosedForCheckout(date)) return true;
    if (isNotAvailableDate(date)) return true;

    return false;
  };

  return (
    <div data-testid="GiftCardDatePicker" className={styles.root}>
      <Row>
        <Col sm={12} xs={24} className={styles.datePickerContainer}>
          <div className={styles.datePickerLabel}>{t('hotel_page.checkin_label')}</div>
          <DatePicker
            key="Check-In"
            bordered={true}
            size="large"
            defaultValue={dates?.[0] || undefined}
            placeholder={t('general.select_your_date')}
            onChange={date => handleDateChange(date, 0)}
            value={dates?.[0]}
            disabledDate={isCheckinDateDisabled}
            className={styles.datePicker}
            ref={checkinRef}
          />
        </Col>
        <Col sm={12} xs={24}>
          <div className={styles.datePickerLabel}>{t('hotel_page.checkout_label')}</div>
          <DatePicker
            key="Check-Out"
            bordered={true}
            size="large"
            defaultValue={dates?.[1] || undefined}
            value={dates?.[1]}
            placeholder={t('general.select_your_date')}
            onChange={date => handleDateChange(date, 1)}
            disabledDate={isCheckoutDateDisabled}
            className={styles.datePicker}
            ref={checkoutRef}
          />
        </Col>
      </Row>
    </div>
  );
};

export default GiftCardDatePicker;
