// Generated with util/create-component.js
import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { Drawer } from 'antd';
import { TablePaginationConfig } from 'antd/es/table/Table';
import { useTranslation } from 'react-i18next';

import {
  ResellerContractsDataContext,
  ResellerContractsActionsContext,
  AuthenticationDataContext,
  SupplierContractsDataContext,
  SupplierContractsActionsContext,
  PropertySettingsDataContext,
} from 'containers/data_context';
import SearchInput from 'components/search_input';
import ContractDiscoverTable from 'components/contracts/contracts_discover_table/contracts_discover_table';
import ContractCreate from 'components/contracts/contract_create/contract_create';
import ContractRequest from 'components/contracts/contract_request/contract_request';
import { IResellerContractPropertyData } from 'components/contracts/contracts_discover_table/contracts_discover_table.types';
import { IReseller } from 'components/reseller_details/reseller_details.types';
import ResellerDetails from 'components/reseller_details/reseller_details';
import { IContractsFilters } from 'components/contracts/contracts_list/contracts_list.types';
import { IProperty } from 'components/gift_card_list/created_gift_cards/created_gift_cards.types';
import USER_ROLE from 'constants/user_role';
import DRAWER_SIZE from 'constants/drawer_size';
import { PROPERTY_CHANNELS } from 'constants/property_channels';

import { IContractDiscoverProps } from './contract_discover.types';
import styles from './contract_discover.module.scss';

const DEFAULT_PAGINATION = { page: 1, limit: 10 };

export const emptyPropertyEntity = {
  id: 0,
  propertyId: 0,
  name: '',
  email: '',
  country: '',
  phone: '',
  timezone: '',
  address: '',
  city: '',
  state: '',
  contract: '',
  propertyType: '',
};
const ContractDiscover: React.FC<IContractDiscoverProps> = () => {
  const resellerContractsData = useContext(ResellerContractsDataContext);
  const resellerContractsActions = useContext(ResellerContractsActionsContext);
  const { userRole } = useContext(AuthenticationDataContext);
  const supplierContractsData = useContext(SupplierContractsDataContext);
  const supplierContractsActions = useContext(SupplierContractsActionsContext);
  const {
    selectedProperty,
    propertiesList: { data: allProperties },
  } = useContext(PropertySettingsDataContext);
  const { t } = useTranslation();

  const {
    loadResellerDiscoverContractsList,
    loadResellerContractsUnitRatesList,
    clearResellerOpenUnitTypesList,
    resellerCreateContract,
    createContractRequest,
  } = resellerContractsActions;

  const {
    loadSupplierDiscoverContractsList,
    loadSupplierRatePlansList,
    clearSupplierAllUnitTypesList,
    supplierCreateContract,
  } = supplierContractsActions;

  const {
    discoverSuppliers: { data: properties, isLoading: isLoadingSuppliers },
    supplierUnits: { data: supplierOpenUnits, isLoading: isLoadingSupplierOpenUnits },
    resellerCreateContractIsLoading,
    resellerRequestContractIsLoading,
  } = resellerContractsData;
  const {
    discoverResellers: { data: resellers, isLoading: isLoadingResellers },
    resellerUnits: { data: supplierAllUnits, isLoading: isLoadingSupplierAllUnits },
    supplierCreateContractIsLoading,
  } = supplierContractsData;
  const isReseller = useMemo(() => userRole === USER_ROLE.RESELLER, [userRole]);

  const [openCreateContractDrawer, setShowCreateContractDrawer] = useState<boolean>(false);
  const [openRequestContractDrawer, setShowRequestContractDrawer] = useState<boolean>(false);
  const [selectedEntity, setSelectedEntity] = useState<IResellerContractPropertyData>(emptyPropertyEntity);
  const [resellerDetails, setResellerDetails] = useState<IReseller | null>(null);
  const [filters, setFilters] = useState<IContractsFilters>({
    pagination: DEFAULT_PAGINATION,
    term: '',
    propertyType: PROPERTY_CHANNELS.CAR,
  });

  const handleLoadDiscoverData = useCallback(() => {
    if (isReseller) {
      loadResellerDiscoverContractsList(filters);
    } else if (selectedProperty) {
      loadSupplierDiscoverContractsList({ ...filters, propertyId: selectedProperty });
    }
  }, [selectedProperty, loadResellerDiscoverContractsList, loadSupplierDiscoverContractsList, filters]);

  useEffect(() => {
    handleLoadDiscoverData();
  }, [filters]);

  useEffect(() => {
    if (selectedProperty) {
      setFilters({ ...filters, pagination: DEFAULT_PAGINATION });
    }
  }, [selectedProperty]);

  const handlePageChange = (currentPagination: TablePaginationConfig) => {
    const { current, pageSize } = currentPagination;
    const pagination = {
      page: current,
      limit: pageSize,
    };

    setFilters({ ...filters, pagination });
  };

  const handleSearchTermChange = useCallback(
    (term: string) => {
      setFilters({ ...filters, pagination: DEFAULT_PAGINATION, term });
    },
    [loadResellerDiscoverContractsList, loadSupplierDiscoverContractsList, filters],
  );

  const handleCreateContract = useCallback(
    (entityData: IResellerContractPropertyData) => {
      setShowCreateContractDrawer(true);
      if (isReseller) {
        loadResellerContractsUnitRatesList({
          propertyId: entityData.propertyId,
          propertyType: entityData.propertyType,
        });
      } else if (selectedProperty) {
        entityData.propertyId = selectedProperty;
        selectedProperty && loadSupplierRatePlansList(selectedProperty);
      }
      setSelectedEntity(entityData);
    },
    [selectedProperty, loadResellerContractsUnitRatesList, loadSupplierRatePlansList],
  );

  const handleRequestContract = useCallback((entityData: IResellerContractPropertyData) => {
    setShowRequestContractDrawer(true);
    setSelectedEntity(entityData);
  }, []);

  const closeCreateContractDrawer = () => {
    setShowCreateContractDrawer(false);
    if (isReseller) {
      clearResellerOpenUnitTypesList();
    } else {
      clearSupplierAllUnitTypesList();
    }
  };

  const closeRequestContractDrawer = () => {
    setShowRequestContractDrawer(false);
  };

  const handleShowResellerDetails = (reseller: IReseller) => setResellerDetails(reseller);

  const searchPlaceholder = useMemo(
    () => (isReseller ? t('contracts.search_for_supplier') : t('contracts.search_for_reseller')),
    [isReseller],
  );

  const propertyType = useMemo(() => {
    if (!selectedEntity) return null;
    if (isReseller) return selectedEntity?.propertyType;
    if (!allProperties || !allProperties?.length) return null;
    return allProperties.find((p: IProperty) => p.value === selectedProperty)?.propertyType;
  }, [selectedEntity, isReseller]);

  return (
    <div data-testid="ContractDiscover" className={styles.root}>
      <div className={styles.search_input_container}>
        <SearchInput onSearch={handleSearchTermChange} placeholder={searchPlaceholder} />
      </div>
      <ContractDiscoverTable
        tableData={isReseller ? properties : resellers}
        isLoading={isReseller ? isLoadingSuppliers : isLoadingResellers}
        isReseller={isReseller}
        onPageChange={handlePageChange}
        onCreateContract={handleCreateContract}
        onRequestContract={handleRequestContract}
        onShowResellerDetails={handleShowResellerDetails}
      />
      <Drawer
        title={t('contracts.create_contract')}
        open={openCreateContractDrawer}
        onClose={closeCreateContractDrawer}
        width={window.innerWidth > 900 ? DRAWER_SIZE.SMALL : window.innerWidth}
        destroyOnClose
      >
        <ContractCreate
          selectedEntity={selectedEntity}
          closeDrawer={closeCreateContractDrawer}
          isReseller={isReseller}
          rateTypes={isReseller ? supplierOpenUnits : supplierAllUnits}
          isLoading={isReseller ? isLoadingSupplierOpenUnits : isLoadingSupplierAllUnits}
          createContract={isReseller ? resellerCreateContract : supplierCreateContract}
          createContractLoading={isReseller ? resellerCreateContractIsLoading : supplierCreateContractIsLoading}
          propertyType={propertyType}
        />
      </Drawer>
      <Drawer
        title={t('contracts.request_contract')}
        open={openRequestContractDrawer}
        onClose={closeRequestContractDrawer}
        width={window.innerWidth > 900 ? DRAWER_SIZE.SMALL : window.innerWidth}
        destroyOnClose
      >
        <ContractRequest
          selectedEntity={selectedEntity}
          closeDrawer={closeRequestContractDrawer}
          requestContract={createContractRequest}
          requestContractLoading={resellerRequestContractIsLoading}
        />
      </Drawer>
      {resellerDetails && (
        <Drawer
          open={!!resellerDetails}
          title={resellerDetails.name}
          width={window.innerWidth > 900 ? DRAWER_SIZE.MEDIUM : window.innerWidth}
          onClose={() => setResellerDetails(null)}
          destroyOnClose={true}
        >
          <ResellerDetails reseller={resellerDetails} />
        </Drawer>
      )}
    </div>
  );
};

export default ContractDiscover;
