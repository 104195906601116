import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FrownOutlined } from "@ant-design/icons";
import moment from "moment";

import LoadingContainer from "components/loading_container";
import Placeholder from "components/placeholder";

import { BookingActionsContext, BookingDataContext } from "containers/data_context";


import RatesTableHeader from "./rates_table_header";
import ReloadContainer from "./reload_container";
import PropertyUnit from "./property_unit";

import styles from "./rates_table.module.scss";

const DEFAULT_OCCUPANCY_PER_UNIT = {};

export default function RatesTable(props) {
  const { propertyType } = props;
  const [isStale, setIsStale] = useState(false);
  const { t } = useTranslation();
  const { propertyUnitsInfo, params, unitRequestParams, channelId } = useContext(BookingDataContext);
  const { setParams, loadPropertyUnitsInfo } = useContext(BookingActionsContext);

  const prevParamsRef = useRef(params);
  useEffect(() => {
    prevParamsRef.current = params;
  });
  const prevParams = prevParamsRef.current;

  const {
    ratesOccupancyPerUnit = DEFAULT_OCCUPANCY_PER_UNIT,
    currency,
    checkinDate = null,
    checkoutDate = null,
    adults,
    children,
  } = params;
  const { data: roomsData, isLoading } = propertyUnitsInfo;
  const [units, setUnits] = useState(roomsData);
  useEffect(() => { setUnits(roomsData) }, [propertyUnitsInfo]);

  const isReloadDisabled = !moment(checkinDate).isValid() || !moment(checkoutDate).isValid();

  const setRatesOccupancyPerUnit = useCallback(
    (updatedOccupancy) => {
      setParams({ ...params, ratesOccupancyPerUnit: updatedOccupancy });
    },
    [params, setParams],
  );

  const handleReload = useCallback(() => {
    loadPropertyUnitsInfo(channelId, params);
  }, [channelId, params, loadPropertyUnitsInfo]);

  useEffect(
    function handleSearchParamsChange() {
      const isCheckinDateChanged = params.checkinDate !== prevParams.checkinDate;
      const isCheckoutDateChanged = params.checkoutDate !== prevParams.checkoutDate;
      const isDatesChaged = isCheckinDateChanged || isCheckoutDateChanged;

      const isCheckinDateMatchesLast = moment(unitRequestParams.checkinDate).isSame(
        params.checkinDate,
        "day",
      );
      const isCheckoutDateMatchesLast = moment(unitRequestParams.checkoutDate).isSame(
        params.checkoutDate,
        "day",
      );
      const isDatesMatchLastRequest = isCheckinDateMatchesLast && isCheckoutDateMatchesLast;

      if (isStale && isDatesMatchLastRequest) {
        setIsStale(false);
        return;
      }

      if (isDatesChaged) {
        setIsStale(true);
        setParams({ ...params, ratesOccupancyPerUnit: {} });
      }
    },
    [isStale, params, prevParams, unitRequestParams, setParams],
  );

  useEffect(
    function handleRoomsInfoUpdate() {
      setIsStale(false);
    },
    [propertyUnitsInfo],
  );

  if (!Array.isArray(units)) {
    return null;
  }

  return (
    <LoadingContainer loading={isLoading}>
      <ReloadContainer disabled={isReloadDisabled} active={isStale} onRefresh={handleReload}>
        <div className={styles.tableContainer}>
          <RatesTableHeader
            propertyUnits={units}
            checkinDate={checkinDate}
            checkoutDate={checkoutDate}
            propertyType={propertyType}
          />
          {units.length ? (
            units.map((unit, rowIndex) => (
              <PropertyUnit
                disabled={isStale}
                unit={unit}
                currency={currency}
                rowIndex={rowIndex}
                checkinDate={checkinDate}
                adultsOccupancy={adults}
                childrenOccupancy={children}
                key={unit.id}
                ratesOccupancyPerUnit={ratesOccupancyPerUnit}
                onRatesOccupancyChange={setRatesOccupancyPerUnit}
                propertyType={propertyType}
              />
            ))
          ) : (
            <Placeholder
              icon={<FrownOutlined />}
              text={t("rates_table.no_available_rates_placeholder")}
            />
          )}
        </div>
      </ReloadContainer>
    </LoadingContainer>
  );
}
