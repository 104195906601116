import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams, Redirect, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Badge } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { decode } from 'js-base64';
import { useMedia } from 'react-media';
import classNames from 'classnames';

import MEDIA_QUERIES from 'constants/media_queries';

import routes from 'routing/routes';
import buildPath from 'utils/build_path';

import LocaleSelectDesktop from 'components/locale_select_desktop/locale_select_desktop';
import { ICartItem } from 'components/gift_card_store_details/gift_card_store_details.types';

import { PropertyGiftCardsActionsContext, PropertyGiftCardsDataContext } from 'containers/data_context';

import styles from './store_nav.module.scss';

const StoreNav: React.FC = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const matchedQueries = useMedia({ queries: MEDIA_QUERIES });
  const isMobile = matchedQueries.xs || matchedQueries.sm || matchedQueries.md;

  const { selectedProperty } = useParams<{ selectedProperty: string }>();

  const icelandicOnlyProperties = [
    'ea232e87-e4fa-49ed-ab3e-5a27146df56b',
    'a404c3d2-b162-48d3-8106-78652c41848a',
    '00351746-b7e0-4ad6-b569-338de0bcb510',
    '152cc4d5-6c25-44bd-847c-57a6e86c4afc',
    '8fc7199d-23fd-4c86-8361-a004f4cff5a8',
    '3564befe-6ae6-4ad2-b39e-0b68b8413fe4',
    '11eba1e3-7a91-4bc6-8ede-d23337927d92',
    '8c592d3b-a888-4ba4-b22e-0d732a9d7a6b',
    '4cd5078b-8b89-4bb1-ab4e-bf1a5bc5af88',
    'hotel-ranga',
    'centerhotels',
  ];

  const languageSelectionEnabled = !icelandicOnlyProperties.includes(selectedProperty);

  const [cartQuantity, setCartQuantity] = useState(0);
  const [redirect, setRedirect] = useState(false);

  const propertyGiftCardsActions = useContext(PropertyGiftCardsActionsContext);
  const hotelGiftCardsData = useContext(PropertyGiftCardsDataContext);

  const {
    propertyInfo: { data },
  } = hotelGiftCardsData;

  const canBuyGiftCard = useRouteMatch(routes.useGiftCardStorePage)?.isExact;
  const canUseGiftCard = useRouteMatch(routes.giftCardStorePage)?.isExact;
  const isMultiPropertyPage = useRouteMatch(routes.giftCardPropertiesPage)?.isExact;

  useEffect(() => {
    if (!languageSelectionEnabled) {
      i18n.changeLanguage('is');
    }
  }, []);

  useEffect(() => {
    async function initApp() {
      try {
        await propertyGiftCardsActions.getPropertyInfo({ propertyId: selectedProperty });
      } catch (e) {
        setRedirect(true);
      }
    }
    if (selectedProperty) {
      initApp();
    }
  }, [propertyGiftCardsActions, selectedProperty]);

  const handleOpenGiftCardStorePage = useCallback(
    (route: string) => {
      const redirectPath = buildPath('', route, { selectedProperty });
      history.push(redirectPath);
    },
    [history],
  );

  useEffect(() => {
    const cartItem = localStorage.getItem(`cart-items-${selectedProperty}`);
    if (cartItem) {
      const items = JSON.parse(decode(cartItem)).items;
      const quantity = items
        .map((item: ICartItem) => item.quantity)
        .reduce((prev: number, curr: number) => prev + curr, 0);
      setCartQuantity(quantity);
    } else {
      setCartQuantity(0);
    }
  }, [history.location.key]);

  const logoContent = data?.logoUrl ? (
    <img
      className={styles.logo}
      src={data?.logoUrl}
      alt="Property logo"
      onClick={() => handleOpenGiftCardStorePage(routes.giftCardStorePage)}
    />
  ) : (
    <div className={styles.title} onClick={() => handleOpenGiftCardStorePage(routes.giftCardStorePage)}>
      {data?.title}
    </div>
  );

  if (redirect) {
    const redirectPath = buildPath('', routes.default);

    return <Redirect to={redirectPath} />;
  }

  return (
    <nav className={styles.root} data-testid="GiftCardStoreNav">
      <div className={isMultiPropertyPage ? styles.multiPropertyContainer : styles.menuContainer}>
        {selectedProperty && (
          <div className={styles.logoContainer}>
            {logoContent}
            {canUseGiftCard && (
              <Button
                onClick={() => handleOpenGiftCardStorePage(routes.useGiftCardStorePage)}
                className={classNames(styles.useGiftCardButton, 'highlighted')}
              >
                {t('gift_card.use_gift_card')}
              </Button>
            )}
            {canBuyGiftCard && (
              <Button
                onClick={() => handleOpenGiftCardStorePage(routes.giftCardStorePage)}
                className={classNames(styles.useGiftCardButton, 'highlighted')}
              >
                {t('gift_card.buy')}
              </Button>
            )}
          </div>
        )}

        <div className={styles.cartLngContainer} style={{ display: 'flex', justifyContent: 'flexEnd' }}>
          {selectedProperty ? (
            isMobile ? (
              <div
                onClick={() => handleOpenGiftCardStorePage(routes.giftCardStoreCartPage)}
                className={styles.cartInfo}
              >
                <Badge count={cartQuantity} color="#4a4a4a">
                  <ShoppingCartOutlined className={styles.cartIcon} />
                </Badge>
              </div>
            ) : (
              <div
                onClick={() => handleOpenGiftCardStorePage(routes.giftCardStoreCartPage)}
                className={styles.cartInfo}
              >
                <ShoppingCartOutlined className={styles.cartIcon} />
                <div className={styles.cartTitle}>{t('gift_card.cart')}</div>
                <div className={styles.cartQuantity}>{cartQuantity}</div>
              </div>
            )
          ) : null}
          {languageSelectionEnabled && <LocaleSelectDesktop showLngIcon={false} isChangingUrlParam={true} />}
        </div>
      </div>
    </nav>
  );
};

export default StoreNav;
