// Generated with util/create-component.js
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Tabs, Divider, Form, Input, Select, Alert } from 'antd';
import { MailOutlined, CarOutlined, TeamOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import countries from 'constants/countries';
import { IGiftCardStoreRecipientDeliveryInfoProps } from './gift_card_store_recipient_delivery_info.types';
import styles from './gift_card_store_recipient_delivery_info.module.scss';
import themeStyles from 'components/gift_card_buy_store_page_layout/gift_card_store_theme.module.scss';

const GiftCardStoreRecipientDeliveryInfo: React.FC<IGiftCardStoreRecipientDeliveryInfoProps> = ({
  showPost,
  showAtHotel,
  pickupAtHotelinfo,
}) => {
  const { t } = useTranslation();

  const emailDelivery = () => {
    return (
      <div>
        <Form.Item
          name={['deliveryInfo', 'email']}
          rules={[
            { required: true, message: t('validation_messages.required') },
            { type: 'email', message: t('general.please_enter_valid_email') },
          ]}
          label={t('general.recipients_email')}
        >
          <Input />
        </Form.Item>
      </div>
    );
  };
  const postDelivery = () => {
    return (
      <div>
        <Row gutter={[8, 8]}>
          <Col xs={24} md={24}>
            <Form.Item
              name={['deliveryInfo', 'deliveryName']}
              rules={[{ required: true, message: t('validation_messages.required') }]}
              label={t('general.other.name')}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24}>
            <Form.Item
              name={['deliveryInfo', 'deliveryAddress']}
              rules={[{ required: true, message: t('validation_messages.required') }]}
              label={t('gift_card.checkout.delivery_form_address_1')}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={16}>
            <Form.Item
              name={['deliveryInfo', 'deliveryCity']}
              rules={[{ required: true, message: t('validation_messages.required') }]}
              label={t('general.city')}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={['deliveryInfo', 'deliveryZipCode']}
              rules={[{ required: true, message: t('validation_messages.required') }]}
              label={t('payment_page.payment_form.billing_address.zip')}
            >
              <Input />
            </Form.Item>
          </Col>
          {/*
          <Col md={8}>
            <Form.Item name={['deliveryInfo', 'deliveryState']} label={t('general.state')}>
              <Input />
            </Form.Item>
          </Col>
          */}
          <Col xs={24} md={24}>
            <Form.Item
              name={['deliveryInfo', 'deliveryCountry']}
              label={t('general.country')}
              rules={[{ required: true, message: t('validation_messages.required') }]}
            >
              <Select showSearch optionFilterProp="children" filterOption={true}>
                {countries.map(country => (
                  <Select.Option key={country.label} value={country.label}>
                    {country.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  };
  const atHotelDelivery = () => {
    return <Alert description={pickupAtHotelinfo} type="info" showIcon />;
  };

  const tabItems = useMemo(() => {
    const tabs = [
      {
        key: '1',
        label: (
          <span>
            <MailOutlined className={styles.tabIcon} /> {t('general.other.email')}
          </span>
        ),
        children: emailDelivery(),
      },
    ];

    if (showPost) {
      tabs.push({
        key: '2',
        label: (
          <span>
            <CarOutlined className={styles.tabIcon} />
            {t('gift_card.checkout.delivery_post')}
          </span>
        ),
        children: postDelivery(),
      });
    }

    if (showAtHotel) {
      tabs.push({
        key: '3',
        label: (
          <span>
            <TeamOutlined className={styles.tabIcon} />
            {t('gift_card.checkout.at_hotel')}
          </span>
        ),
        children: atHotelDelivery(),
      });
    }

    return tabs;
  }, [showPost, showAtHotel, pickupAtHotelinfo]);

  return (
    <div
      data-testid="GiftCardStoreRecipientDeliveryInfo"
      className={classNames(styles.root, themeStyles.root, 'roundedWrapper')}
    >
      <Row gutter={[8, 8]}>
        <Col md={8}>
          <p className={classNames(styles.title, 'highlighted')}>{t('gift_card.checkout.delivery_form_send_via')}</p>
          <p className={styles.description}>{t('gift_card.checkout.delivery_form_how_to_send')}</p>
        </Col>
        <Col md={16}>
          <Tabs type="card" defaultActiveKey="1" destroyInactiveTabPane items={tabItems} />
        </Col>
      </Row>
      <Divider dashed />
      <Row gutter={[8, 8]}>
        <Col md={8}>
          <p className={classNames(styles.title, 'highlighted')}>
            {t('gift_card.checkout.delivery_form_recepient_details')}
          </p>
          <p className={styles.description}>{t('gift_card.checkout.delivery_form_recepient_who')}</p>
        </Col>
        <Col md={16}>
          <Form.Item label={t('gift_card.checkout.delivery_form_optional_name')} name={['deliveryInfo', 'name']}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default GiftCardStoreRecipientDeliveryInfo;
