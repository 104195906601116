// Generated with util/create-component.js
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Select } from 'antd';

import { IGiftCardPdfDetailsProps, IPdfTemplate } from './gift_card_pdf_details.types';
import styles from './gift_card_pdf_details.module.scss';
import GiftCardPdfMultipleForms from './gift_card_pdf_multiple_forms/gift_card_pdf_multiple_forms';
import Loading from 'components/loading';

const GiftCardPdfDetails: React.FC<IGiftCardPdfDetailsProps> = ({
  giftCard,
  onCancel,
  pdfTemplates,
  isLoadingPdfTemplates,
  onLoadPdfTemplates,
  filledSections,
  isLoadingFilledSections,
  onLoadFilledSections,
  onUpdateFilledSections,
}) => {
  const { t } = useTranslation();

  const [selectedTemplateId, setSelectedTemplateId] = useState<number>(
    giftCard.pdfTemplateId || pdfTemplates?.find((x: IPdfTemplate) => x?.default === true)?.id || 3,
  );

  useEffect(() => {
    onLoadPdfTemplates();
  }, []);

  useEffect(() => {
    if (selectedTemplateId === null) {
      return;
    }

    onLoadFilledSections(giftCard.id, selectedTemplateId);
  }, [selectedTemplateId]);

  const onChange = (value: number) => {
    setSelectedTemplateId(value);
  };

  const pdfTemplateOptions = pdfTemplates
    ?.map((pdfTemplate: IPdfTemplate) => ({
      label: pdfTemplate.name,
      value: pdfTemplate.id,
      default: pdfTemplate.default,
    }))
    .sort((a, b) => {
      if (a.default) {
        return -1;
      }
      if (b.default) {
        return 1;
      }
      return 0;
    });

  const pdfSections = pdfTemplates?.find((x: IPdfTemplate) => x.id === selectedTemplateId)?.pdfSections || [];

  const isDefaultTemplate = pdfTemplates?.find((x: IPdfTemplate) => x.id === selectedTemplateId)?.default === true;

  return (
    <div data-testid="GiftCardPdfDetails" className={styles.root}>
      {isLoadingPdfTemplates ? (
        <Loading />
      ) : (
        <Form.Item label={t('gift_card.pdf_template')}>
          <Select
            data-testid={'PdfTemplates'}
            showSearch
            defaultValue={selectedTemplateId}
            placeholder={t('gift_card.pdf_template')}
            optionFilterProp="label"
            onChange={onChange}
            filterOption={true}
            options={pdfTemplateOptions}
          />
        </Form.Item>
      )}
      {isLoadingFilledSections && <Loading />}

      {filledSections && selectedTemplateId && !isLoadingPdfTemplates && !isLoadingFilledSections && pdfTemplates && (
        <GiftCardPdfMultipleForms
          pdfSections={pdfSections}
          filledSections={filledSections}
          onLoadFilledSections={onLoadFilledSections}
          onCancel={onCancel}
          giftCard={giftCard}
          selectedTemplateId={selectedTemplateId}
          defaultTemplate={isDefaultTemplate}
          onSaveChanges={onUpdateFilledSections}
        />
      )}
    </div>
  );
};

export default GiftCardPdfDetails;
