import React, { useContext, useEffect } from 'react';
import ReactGA from 'react-ga4';
import classNames from 'classnames';

import { SHARP_CORNERS } from 'constants/store_settings_corner_style';
import StoreNav from 'components/store_nav/store_nav';
import { PropertyGiftCardsDataContext } from 'containers/data_context';
import useTrackingCookieConsent from 'hooks/useTrackingCookieConsent';

import styles from './gift_card_buy_store_page_layout.module.scss';
import { IGiftCardStorePageProps } from './gift_card_buy_store_page_layout.types';
import giftCardStoreThemeStyles from './gift_card_store_theme.module.scss';

const GiftCardStorePageLayout: React.FC<IGiftCardStorePageProps> = ({ children, className }) => {
  const { propertyInfo } = useContext(PropertyGiftCardsDataContext);
  const isTrackingAllowed: boolean = useTrackingCookieConsent();

  useEffect(() => {
    if (propertyInfo?.data?.storeSetting) {
      const { titleFont, textFont, tagColor, gaTrackingId } = propertyInfo?.data?.storeSetting;
      document.documentElement.style.setProperty('--titleFont', titleFont);
      document.documentElement.style.setProperty('--textFont', textFont);
      document.documentElement.style.setProperty('--tagColor', tagColor);

      if (isTrackingAllowed && gaTrackingId && gaTrackingId !== '') {
        ReactGA.initialize([
          {
            trackingId: gaTrackingId,
          },
        ]);
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
      }
    }
  }, [propertyInfo?.data?.storeSetting]);

  return (
    <div
      className={classNames(
        giftCardStoreThemeStyles.root,
        propertyInfo?.data?.storeSetting?.cornerStyle === SHARP_CORNERS ? '' : giftCardStoreThemeStyles.roundedTheme,
        className,
      )}
    >
      <header>
        <StoreNav />
      </header>
      <section className={styles.giftCardStoreContent}>{children}</section>
    </div>
  );
};

export default GiftCardStorePageLayout;
