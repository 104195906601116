import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Radio, Button, InputNumber, Statistic, Row, Col, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import PURCHASE_TYPE from 'constants/gift_card_purchase';
import notification from 'utils/notification/notification';

import { IGiftCardIssueFormProps } from './gift_card_issue_form.types';
import styles from './gift_card_issue_form.module.scss';

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
};

const GiftCardIssueForm: React.FC<IGiftCardIssueFormProps> = ({
  giftCardType,
  giftCardTypeList,
  onCancel,
  onSaveChanges,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const netPrice = Form.useWatch('netPrice', form);
  const quantity = Form.useWatch('quantity', form);
  const giftCardTypeSelected = Form.useWatch('giftCardType', form);

  const requiredRules = [{ required: true, message: t('validation_messages.required') }];
  const initialValues = {
    netPrice: giftCardType?.price || 0,
    purchaseState: PURCHASE_TYPE.UNPAID,
    quantity: 1,
  };

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const handleSubmit = async (): Promise<void> => {
    const selectedGiftCard = giftCardType?.id || giftCardTypeSelected;

    if (!selectedGiftCard) return;
    const updatedValues = form.getFieldsValue(true);

    try {
      setSubmitLoading(true);
      await onSaveChanges(selectedGiftCard, updatedValues);

      notification.withIcon('success', t('gift_card.issued_notification'));
      onCancel();
    } catch (e) {
      console.log(e);
      notification.withIcon('error', t('general.error_description'));
    }
    setSubmitLoading(false);
  };

  const propertyTypeOptions = [
    { value: PURCHASE_TYPE.PAID, label: t('gift_card.purchase.paid') },
    { value: PURCHASE_TYPE.UNPAID, label: t('gift_card.purchase.unpaid') },
  ];

  const totalPrice = () => {
    return netPrice * quantity || 0;
  };

  const limitExceeded = (length: number) => {
    return length >= quantity;
  };

  const setProductPrice = (id: number) => {
    if (!giftCardTypeList) return;

    const giftCardType = giftCardTypeList.find(card => card.id === id);
    form.setFieldsValue({ netPrice: giftCardType?.price });
  };

  const priceFormatter = (value: string | undefined) => {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  return (
    <div data-testid="GiftCardIssueFormMultiple" className={styles.root}>
      <Form
        name="GiftCardIssueForm"
        onFinish={handleSubmit}
        form={form}
        initialValues={initialValues}
        {...formItemLayout}
      >
        {giftCardTypeList && (
          <Form.Item name="giftCardType" label={t('gift_card.purchase.product')} rules={requiredRules}>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={true}
              placeholder={t('gift_card.purchase.product')}
              onChange={setProductPrice}
            >
              {giftCardTypeList.map((giftCardType, index) => (
                <Select.Option key={index} value={giftCardType.id}>
                  {giftCardType.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {giftCardType && (
          <Form.Item label={t('gift_card.purchase.product')} rules={requiredRules}>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={true}
              placeholder={t('gift_card.purchase.product')}
              disabled
              defaultValue={giftCardType.title}
            ></Select>
          </Form.Item>
        )}

        <Form.Item name="name" rules={[...requiredRules, { max: 80 }]} label={t('general.other.name')}>
          <Input placeholder={t('general.other.name')} />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[...requiredRules, { type: 'email', message: t('general.please_enter_valid_email') }]}
          label={t('general.other.email')}
        >
          <Input placeholder={t('general.other.email')} />
        </Form.Item>

        <Form.Item name="companyRegistrationNumber" label={t('gift_card.other.kennitala')}>
          <Input placeholder={t('gift_card.other.kennitala')} />
        </Form.Item>

        <Form.Item name="phone" label={t('general.other.phone')}>
          <Input placeholder={t('general.other.phone')} />
        </Form.Item>

        <Form.Item name="address" label={t('general.address')}>
          <Input placeholder={t('general.address')} />
        </Form.Item>

        <Form.Item name="city" label={t('general.city')}>
          <Input placeholder={t('general.city')} />
        </Form.Item>

        <Form.Item name="country" label={t('general.country')}>
          <Input placeholder={t('general.country')} />
        </Form.Item>

        <Form.Item
          name="netPrice"
          rules={[{ required: true, type: 'number', message: t('validation_messages.required'), min: 0 }]}
          label={t('gift_card.net_price')}
        >
          <InputNumber controls={false} addonAfter="ISK" placeholder={t('gift_card.net_price')} precision={0} />
        </Form.Item>

        <Form.Item
          name="quantity"
          rules={[{ required: true, type: 'number', message: t('validation_messages.required'), min: 1 }]}
          label={t('general.quantity')}
        >
          <InputNumber min={1} controls={true} placeholder={t('general.quantity')} precision={0} />
        </Form.Item>

        <Form.Item name="recipients" label="Recipients">
          <Form.List name="recipientsInfo">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row key={key} justify="space-between" align="top" gutter={4} className={styles.recipientsList}>
                    <Col xs={11}>
                      <Form.Item
                        {...restField}
                        name={[name, 'name']}
                        rules={[{ required: true, message: t('gift_card.purchase.missing_name') }]}
                      >
                        <Input placeholder={t('general.other.name')} />
                      </Form.Item>
                    </Col>
                    <Col xs={11}>
                      <Form.Item
                        {...restField}
                        name={[name, 'email']}
                        rules={[...requiredRules, { type: 'email', message: t('general.please_enter_valid_email') }]}
                      >
                        <Input placeholder={t('general.other.email')} />
                      </Form.Item>
                    </Col>
                    <Col xs={1} className={styles.removeButton}>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    disabled={limitExceeded(fields.length)}
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    {t('gift_card.purchase.add_recipient')}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item name="purchaseState" label={t('gift_card.purchase_state')} rules={requiredRules}>
          <Radio.Group options={propertyTypeOptions} optionType="button" buttonStyle="outline" />
        </Form.Item>

        <div className={styles.submit_section}>
          <div className={styles.total_net_price}>
            <Statistic
              title={t('gift_card.other.total_price')}
              prefix={`${quantity}x ${priceFormatter(netPrice)} = `}
              value={totalPrice()}
              groupSeparator="."
            />
          </div>

          <div className={styles.button_section}>
            <Button
              loading={submitLoading}
              className={styles.submit_button}
              type="primary"
              htmlType="submit"
              size="large"
            >
              {t('gift_card.create_order')}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default GiftCardIssueForm;
