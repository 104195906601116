import moment from 'moment';

import { FULL_TIME_FORMAT } from 'constants/date_formats';

import dateFormatter from 'utils/date_formatter/date_formatter';
import isAccommodationProperty from 'utils/is_accommodation_property';
import { CANCELLATION_POLICY_LOGIC } from 'constants/cancellation_policies';


function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

const getCancellationPolicyPerBooking = units => {
  const unitsWithCancellationPolicies = units?.filter(unit => unit.cancellationPolicy);
  if (!unitsWithCancellationPolicies?.length) return null;

  const cancellationPoliciesLogics = units?.map(unit => unit.cancellationPolicy.cancellationPolicyLogic);
  const uniqueCPLogic = cancellationPoliciesLogics.filter(onlyUnique);
  if (uniqueCPLogic.length == 1 && uniqueCPLogic[0] !== CANCELLATION_POLICY_LOGIC.DEADLINE) return units[0].cancellationPolicy;

  let lessStrictCancellationPolicyNumOfHours = 0;
  let lessStrictCancellationPolicy = null;

  unitsWithCancellationPolicies.map(unit => unit.cancellationPolicy).forEach(cp => {
    const numOfHoursPerUnit = cp.cancellationPolicyDeadline * (cp.cancellationPolicyDeadlineType === 'hours' ? 1 : 24);
    if (numOfHoursPerUnit > lessStrictCancellationPolicyNumOfHours) {
      lessStrictCancellationPolicy = cp;
      lessStrictCancellationPolicyNumOfHours = numOfHoursPerUnit;
    }
  });

  return lessStrictCancellationPolicy;
};

export const formatDate = date => dateFormatter.toUiShortMonth(date);

export const hasCarBookingAdditionalDay = ({ pickUpTime, dropOffTime }) => {
  return moment(dropOffTime, FULL_TIME_FORMAT).isAfter(moment(pickUpTime, FULL_TIME_FORMAT));
}

export const formatPrice = (currency, total) =>
  `${currency.toUpperCase()} ${(Math.round((total || 1000) * 100) / 100).toFixed(2)}`;

const totalNights = (booking) => {
  const { arrivalDate, departureDate, rooms } = booking;

  const numOfFullDays = Math.floor((new Date(departureDate).getTime() - new Date(arrivalDate).getTime()) / (1000 * 3600 * 24));

  if (rooms?.[0]?.meta?.pickUpTime && rooms?.[0]?.meta?.dropOffTime) {
    const hasAdditionalDay = hasCarBookingAdditionalDay(rooms?.[0]?.meta);

    if (hasAdditionalDay) return numOfFullDays + 1;
  }

  return numOfFullDays;
}

export const formatRoomsToUnits = (rooms) => {
  const units = rooms?.map(room => {
    const { roomTypeCode, ...rest } = room;
    return {
      ...rest,
      unitTypeCode: roomTypeCode,
    };
  });

  return units;
}

export const formatCarPickUpDate = (unit, pickUpDate) => {
  const date = pickUpDate + ' ' + unit?.meta?.pickUpTime;

  return dateFormatter.toUiDateTime(date);
}

export const formatCarDropOffDate = (unit, dropOffDate) => {
  const date = dropOffDate + ' ' + unit?.meta?.dropOffTime;

  return dateFormatter.toUiDateTime(date);
}

export const formatBookingsList = bookings => {
  return bookings.map(booking => {
    const {
      arrivalDate,
      currency,
      departureDate,
      rooms,
      vehicles,
      total,
      propertyType,
      ...rest
    } = booking;

    return {
      ...rest,
      propertyType,
      arrivalDate: formatDate(arrivalDate),
      departureDate: formatDate(departureDate),
      totalNights: totalNights(booking),
      price: formatPrice(currency, total),
      cancellationPolicy: getCancellationPolicyPerBooking(rooms),
      propertyType: propertyType,
      units: isAccommodationProperty(propertyType) ? formatRoomsToUnits(rooms) : formatVehiclesToUnits(vehicles),
    };
  });
};

export const formatBooking = booking => {
  const {
    arrivalDate,
    currency,
    departureDate,
    rooms,
    vehicles,
    total,
    propertyType,
    ...rest
  } = booking;

  return {
    ...rest,
    arrivalDate: formatDate(arrivalDate),
    departureDate: formatDate(departureDate),
    totalNights: totalNights(booking),
    price: formatPrice(currency, total),
    cancellationPolicy: getCancellationPolicyPerBooking(rooms),
    propertyType: propertyType,
    units: isAccommodationProperty(propertyType) ? formatRoomsToUnits(rooms) : formatVehiclesToUnits(vehicles),
  };
};

export const formatUnitsToRooms = (units) => {
  const rooms = units.map(unit => {
    const { drivers, unitTypeCode, ...rest } = unit;
    return {
      ...rest,
      roomTypeCode: unitTypeCode,
    };
  });

  return rooms;
}

export const formatUnitsToVehicles = (units) => {
  const vehicles = units.map(u => {
    const { guests, unitTypeCode, ...rest } = u;
    return {
      ...rest,
      drivers: guests,
      vehicleTypeCode: unitTypeCode,
    };
  });

  return vehicles;
}

const formatVehicleDrivers = (drivers) => {
  const formattedDrivers = drivers?.map(driver => {
    const { firstName, lastName, address } = driver;
    return {
      name: firstName,
      surname: lastName,
      nationality: address?.countryCode
    }
  });

  return formattedDrivers;
}

export const formatVehiclesToUnits = (vehicles) => {
  const units = vehicles.map(r => {
    const { vehicleTypeCode, drivers, ...rest } = r;
    return {
      ...rest,
      drivers: formatVehicleDrivers(drivers),
      unitTypeCode: vehicleTypeCode,
    };
  });

  return units;
}

export const formatBookingUnits = ({ propertyType, units }) => {
  if (isAccommodationProperty(propertyType)) {
    return { rooms: formatUnitsToRooms(units) }
  }
  return { vehicles: formatUnitsToVehicles(units) };
}

export const formatUpdatedBooking = (booking, propertyType) => {
  if (isAccommodationProperty(propertyType)) {
    const { arrivalDate, departureDate, total, currency, rooms, ...rest } = booking;
    return {
      ...rest,
      units: formatRoomsToUnits(rooms),
      arrivalDate: formatDate(arrivalDate),
      departureDate: formatDate(departureDate),
      price: formatPrice(currency, total),
      cancellationPolicy: getCancellationPolicyPerBooking(rooms),
    }
  }

  const { vehicles, pickupDate, dropoffDate, channelId, total, currency, ...rest } = booking;
  return {
    ...rest,
    propertyChannelId: channelId,
    units: formatVehiclesToUnits(vehicles),
    arrivalDate: formatCarPickUpDate(vehicles?.[0], pickupDate),
    departureDate: formatCarDropOffDate(vehicles?.[0], dropoffDate),
    price: formatPrice(currency, total),
    cancellationPolicy: getCancellationPolicyPerBooking(vehicles),
  };
}
