import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, Space, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import { Moment } from 'moment';

import SearchInput from 'components/search_input/search_input';
import GiftCardPurchases from 'components/gift_card_purchases/gift_card_purchases';
import GiftCardPurchaseFilters from 'components/gift_card_purchase_filters/gift_card_purchase_filters';
import { IGiftCardPurchase } from 'components/gift_card_purchases/gift_card_purchases.types';
import {
  PropertyGiftCardsActionsContext,
  PropertyGiftCardsDataContext,
  PropertySettingsDataContext,
} from 'containers/data_context';
import { DATE_API_FORMAT } from 'constants/date_formats';
import PURCHASE_TYPE from 'constants/gift_card_purchase';
import GIFTCARD_STATUS from 'constants/gift_card_status';
import DRAWER_SIZE from 'constants/gift_card_drawer_size';
import {
  IGiftCardMultipleIssueFormValues,
  IGiftCardType,
} from 'components/gift_card_issue_form/gift_card_issue_form.types';
import GiftCardIssueForm from 'components/gift_card_issue_form/gift_card_issue_form';

import styles from './gift_card_purchase_page.module.scss';
import { IPurchaseFilters } from './gift_card_purchase_page.types';

const DEFAULT_PAGINATION = { page: 1, limit: 10 };
const DEFAULT_ORDER = { orderBy: 'created_at', orderDirection: 'desc' };
const DEFAULT_FILTERS: IPurchaseFilters = { pagination: DEFAULT_PAGINATION, ...DEFAULT_ORDER };

const GiftCardPurchasePage: React.FC = () => {
  const { t } = useTranslation();

  const propertyGiftCardsActions = useContext(PropertyGiftCardsActionsContext);
  const propertyGiftCardsData = useContext(PropertyGiftCardsDataContext);
  const propertySettingsData = useContext(PropertySettingsDataContext);
  const { getGiftCardsPurchases } = useContext(PropertyGiftCardsActionsContext);
  const { selectedProperty } = useContext(PropertySettingsDataContext);
  const [allFilters, setFilters] = useState(DEFAULT_FILTERS);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const { giftCardTypes } = propertyGiftCardsData;
  const {
    giftCardsPurchases: { data, isLoading },
  } = useContext(PropertyGiftCardsDataContext);

  useEffect(() => {
    if (!selectedProperty) return;

    getGiftCardsPurchases({ ...allFilters, propertyId: selectedProperty });
    propertyGiftCardsActions.getGiftCardTypes({ propertyId: selectedProperty });
  }, [selectedProperty, allFilters]);

  const handleSearch = async (term: string) => {
    setFilters({ ...allFilters, term: term, pagination: DEFAULT_PAGINATION });
  };

  const handleSelectStatus = async (status: string) => {
    if (status === PURCHASE_TYPE.PAID) {
      setFilters({ ...allFilters, status: PURCHASE_TYPE.PAID, pagination: DEFAULT_PAGINATION });
    } else if (status === PURCHASE_TYPE.UNPAID) {
      setFilters({ ...allFilters, status: PURCHASE_TYPE.UNPAID, pagination: DEFAULT_PAGINATION });
    } else {
      delete allFilters['status'];
      setFilters({ ...allFilters, pagination: DEFAULT_PAGINATION });
    }
  };

  const handleChange = useCallback(
    (
      currentPagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<IGiftCardPurchase> | SorterResult<IGiftCardPurchase>[],
    ) => {
      let appliedFilters = JSON.parse(JSON.stringify(allFilters));
      Object.keys(filters).map((filterName: string) => {
        if (!filters[filterName]) {
          delete appliedFilters[filterName];
        } else {
          appliedFilters[filterName] = filters[filterName]?.[0];
        }
      });

      if (!(sorter as SorterResult<IGiftCardPurchase>)?.order) {
        appliedFilters = { ...appliedFilters, ...DEFAULT_ORDER };
      } else {
        appliedFilters.orderBy = String((sorter as SorterResult<IGiftCardPurchase>)?.field)
          .replace(/[A-Z]/g, '_' + '$&')
          .toLowerCase();
        appliedFilters.orderDirection = (sorter as SorterResult<IGiftCardPurchase>).order === 'ascend' ? 'asc' : 'desc';
      }

      const { current, pageSize } = currentPagination;

      appliedFilters.pagination = {
        page: allFilters.pagination?.page !== current ? current : 1,
        limit: pageSize || 10,
      };

      setFilters(appliedFilters);
    },
    [allFilters],
  );

  const paginationData = {
    current: allFilters.pagination?.page,
    total: data?.meta?.total,
    pageSize: allFilters.pagination?.limit,
    hideOnSinglePage: true,
    showSizeChanger: false,
  };

  const [open, setOpen] = useState(false);

  const handleDatePickerChange = (dates: [Moment | null, Moment | null] | null) => {
    if (dates === null) {
      const appliedFilters = JSON.parse(JSON.stringify(allFilters));
      delete appliedFilters['soldFrom'];
      delete appliedFilters['soldTo'];
      setFilters(appliedFilters);
    } else if (dates[0] && dates[1]) {
      const soldFrom = dates[0].format(DATE_API_FORMAT);
      const soldTo = dates[1].format(DATE_API_FORMAT);
      setFilters({ ...allFilters, soldFrom: soldFrom, soldTo: soldTo, pagination: DEFAULT_PAGINATION });
    }

    setOpen(false);
  };

  const openDrawer = useCallback(() => {
    setShowDrawer(true);
  }, [selectedProperty]);

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  const handleIssuingMultipleGiftCard = async (giftCardId: number, issuedParams: IGiftCardMultipleIssueFormValues) => {
    await propertyGiftCardsActions.postGiftCardIssuedMultiple({
      propertyId: propertySettingsData.selectedProperty,
      giftCardId,
      issuedParams,
    });

    getGiftCardsPurchases({ ...allFilters, propertyId: selectedProperty });
  };

  const giftCardsForIssuingList = (giftCardTypesList: IGiftCardType[]) => {
    return giftCardTypesList?.filter(giftCardType => !(giftCardType.status === GIFTCARD_STATUS.ARCHIVED));
  };

  return (
    <div className={styles.root} data-testid="GiftCardPurchasePage">
      <div className={styles.giftCardPurchaseList}>
        <div className={styles.create_button}>
          <Button onClick={openDrawer} type="primary" htmlType="submit" size="large">
            {t('gift_card.purchase.create_new')}
          </Button>
        </div>

        <div className={styles.flexContainer}>
          <SearchInput
            onSearch={handleSearch}
            placeholder={t('gift_card.purchase.search_label')}
            className={styles.searchInput}
            bordered={false}
          />
          <Space className={styles.spaceStyle}>
            <GiftCardPurchaseFilters
              onSelectStatus={handleSelectStatus}
              onDatePickerChange={handleDatePickerChange}
              setOpenDatePicker={setOpen}
              openDatePicker={open}
            />
          </Space>
        </div>

        <GiftCardPurchases
          giftCardsPurchases={data?.data}
          onChange={handleChange}
          pagination={paginationData}
          isLoading={isLoading}
        />
      </div>

      <Drawer
        destroyOnClose
        title={t('gift_card.purchase.create_new')}
        open={showDrawer}
        onClose={closeDrawer}
        width={window.innerWidth > 900 ? DRAWER_SIZE.LARGE : window.innerWidth}
      >
        <GiftCardIssueForm
          onCancel={closeDrawer}
          onSaveChanges={handleIssuingMultipleGiftCard}
          giftCardTypeList={giftCardsForIssuingList(giftCardTypes?.data)}
        />
      </Drawer>
    </div>
  );
};

export default GiftCardPurchasePage;
