// Generated with util/create-component.js
import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { ISearchInputProps } from './search_input.types';
import styles from './search_input.module.scss';

const SearchInput: React.FC<ISearchInputProps> = ({
  placeholder,
  className,
  addonAfter = null,
  onSearch,
  bordered = true,
}) => {
  const debounce = (func: (term: string) => void, timeout = 500) => {
    let timer: ReturnType<typeof setTimeout>;
    return (term: string) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(term);
      }, timeout);
    };
  };

  const debounceHandleSearch = useMemo(() => debounce(onSearch, 500), [onSearch]);

  return (
    <div data-testid="SearchInput" className={classNames(styles.root, { [`${className}`]: className })}>
      <Input
        size="large"
        prefix={<SearchOutlined className={styles.search_icon} />}
        placeholder={placeholder}
        onChange={e => debounceHandleSearch(e.target.value)}
        maxLength={52}
        addonAfter={addonAfter}
        bordered={bordered}
      />
    </div>
  );
};

export default SearchInput;
