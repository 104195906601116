import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-media';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import {
  BookingActionsContext,
  BookingDataContext,
  PropertyGiftCardsActionsContext,
  PropertyGiftCardsDataContext,
} from 'containers/data_context';

import routes from 'routing/routes';

import MEDIA_QUERIES from 'constants/media_queries';
import buildPath from 'utils/build_path';
import calculateSummaryParams from 'utils/calculate_summary_params';

import DateSelect from './date_select';
import MinPricePanel from './min_price_panel';
import MobileSummary from './mobile_summary';
import OccupancySetting from './occupancy_settings';
import Summary from './summary';

import styles from './search_section.module.scss';
import isAccommodationProperty from 'utils/is_accommodation_property';

export default function SearchSection({ propertyType }) {
  const [selectedRatesPerUnit, setSelectedRatesByRoom] = useState({});
  const [missingSpaces, setMissingSpaces] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const bookingData = useContext(BookingDataContext);
  const { channelId, params, property, propertyUnitsInfo, bestOffer, closedDates } = bookingData;
  const { setParams, loadPropertyUnitsInfo, saveDataToStorage, clearDataFromStorage } = useContext(BookingActionsContext);
  const matchedQueries = useMedia({ queries: MEDIA_QUERIES });
  const history = useHistory();
  const isMobile = matchedQueries.xs || matchedQueries.sm || matchedQueries.md;
  const { data: propertyRooms, isLoading } = propertyUnitsInfo;
  const { data: propertyData } = property;

  const { getGiftCardClosedDates } = useContext(PropertyGiftCardsActionsContext);

  const { ratesOccupancyPerUnit, currency, checkinDate, checkoutDate, adults, children } = params;

  const isRateSelected = Boolean(Object.keys(selectedRatesPerUnit).length);
  const isDatesSelected = moment(checkinDate).isValid() && moment(checkoutDate).isValid();

  const handleBook = useCallback(() => {
    saveDataToStorage(bookingData);

    const checkoutPagePath = buildPath(history.location.search, routes.checkoutPage, { channelId });

    history.push(checkoutPagePath);
  }, [bookingData, saveDataToStorage, history, channelId]);

  const handleSearch = useCallback(() => {
    clearDataFromStorage();
    loadPropertyUnitsInfo(channelId, params);
  }, [channelId, params, loadPropertyUnitsInfo, clearDataFromStorage]);

  const handleSearchChange = useCallback(
    params => {
      clearDataFromStorage();
      params.ratesOccupancyPerUnit = {};
      setParams(params);
    },
    [clearDataFromStorage, setParams],
  );

  useEffect(
    function setSummaryParams() {
      const summaryParams = calculateSummaryParams(propertyRooms, ratesOccupancyPerUnit, params);

      if (!summaryParams) {
        return;
      }

      setTotalPrice(summaryParams.total);
      setSelectedRatesByRoom(summaryParams.selectedRatesPerUnit);
    },
    [propertyRooms, ratesOccupancyPerUnit],
  );

  useEffect(
    function calculateMissingSpaces() {
      let availableAdultSpaces = 0;
      let availableChildSpaces = 0;

      Object.values(selectedRatesPerUnit).forEach(room => {
        room.selectedRates.forEach(rate => {
          const { amount, occupancy } = rate;

          availableAdultSpaces += amount * occupancy?.adults;
          availableChildSpaces += amount * occupancy?.children + amount * occupancy?.infants;
        });
      });

      const missingAdults = adults - availableAdultSpaces;
      const missingChildren = children - availableChildSpaces - (availableAdultSpaces - adults);

      const newMissingSpaces = missingAdults > 0 || missingChildren > 0 ? missingAdults + missingChildren : 0;

      setMissingSpaces(newMissingSpaces);
    },
    [selectedRatesPerUnit, adults, children],
  );

  const {
    giftCardClosedDates: { data: closedDatesPerRate },
  } = useContext(PropertyGiftCardsDataContext);

  const SummaryComponent = isMobile ? MobileSummary : Summary;
  const wrapperClasses = [styles.searchPanelWrapper];

  if (!propertyData.photos.length) {
    wrapperClasses.push(styles.searchPanelNoOffset);
  }
  useEffect(() => {
    if (channelId && getGiftCardClosedDates) {
      getGiftCardClosedDates(channelId);
    }
  }, [getGiftCardClosedDates, channelId]);

  return (
    <div className={styles.root}>
      <div className={styles.stickyContainer}>
        <div className={wrapperClasses.join(' ')}>
          <MinPricePanel bestOffer={bestOffer} params={params} />
          <div className={styles.searchSection}>
            <DateSelect
              bookingParams={params}
              handleSearchChange={handleSearchChange}
              closedDates={closedDates?.data}
              propertyType={propertyType}
              closedDatesPerRate={closedDatesPerRate}
            />
            {isAccommodationProperty(propertyType) && (
              <OccupancySetting bookingParams={params} handleSearchChange={handleSearchChange} />
            )}
            <SummaryComponent
              selectedRatesPerUnit={selectedRatesPerUnit}
              bookingParams={params}
              isDatesSelected={isDatesSelected}
              isRateSelected={isRateSelected}
              totalPrice={totalPrice}
              missingSpaces={missingSpaces}
              currency={currency}
              loading={isLoading}
              onBook={handleBook}
              onSearch={handleSearch}
              propertyType={propertyType}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
