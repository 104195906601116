// Generated with util/create-component.js
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Space, Divider, Typography } from 'antd';
import { TablePaginationConfig } from 'antd/es/table/Table';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';

import dateFormatter from 'utils/date_formatter';
import USER_ROLE from 'constants/user_role';
import { PROPERTY_CHANNELS } from 'constants/property_channels';
import CONTRACT_STATUS from 'constants/contract_status';
import CONTRACT_INITIATOR from 'constants/contract_initiator';
import { IContract } from 'components/contracts/pending/pending_contracts/pending_contracts.types';

import { IContractsListProps } from './contracts_list.types';
import styles from './contracts_list.module.scss';

const { Link } = Typography;
const COLUMN_WIDTH = 150;

const ContractsList: React.FC<IContractsListProps> = ({
  userRole,
  contracts,
  pagination,
  isLoading,
  isActiveContracts = false,
  onPageChange,
  onOpenContractDetails,
  onBook,
}) => {
  const { t } = useTranslation();

  const isReseller = userRole === USER_ROLE.RESELLER;

  const handlePageChange = useCallback(
    (currentPagination: TablePaginationConfig) => {
      const { current, pageSize } = currentPagination;
      const pagination = {
        page: current,
        limit: pageSize,
      };
      onPageChange(pagination);
    },
    [onPageChange],
  );

  const renderStatus = useCallback((contract: IContract) => {
    if (contract.status === CONTRACT_STATUS.APPROVED) {
      return <div className={styles.statusLabel}>{t('contracts.active')}</div>;
    }
    if (
      (contract.initiator === CONTRACT_INITIATOR.RESELLER && isReseller) ||
      (!isReseller && contract.initiator === CONTRACT_INITIATOR.SUPPLIER)
    ) {
      return (
        <div className={styles.statusLabel}>
          {t('contracts.proposed')}
          <ArrowRightOutlined className={styles.statusProposedIcon} />
        </div>
      );
    }
    return (
      <div className={styles.statusLabel}>
        {t('contracts.requested')}
        <ArrowLeftOutlined className={styles.statusRequestedIcon} />
      </div>
    );
  }, []);

  const renderActions = useCallback(
    (contract: IContract) => {
      return (
        <Space className={styles.actionsLinksWrapper} split={<Divider type="vertical" />} size={2}>
          {isActiveContracts && userRole === USER_ROLE.RESELLER && contract.propertyType === PROPERTY_CHANNELS.CAR && (
            <Link onClick={() => onBook?.(contract)}>{t('general.book')}</Link>
          )}
          <Link onClick={() => onOpenContractDetails(contract)}>{t('contracts.view')}</Link>
        </Space>
      );
    },
    [isActiveContracts, userRole, onBook, onOpenContractDetails],
  );

  const contractListColumns = useMemo(
    () =>
      [
        {
          title: t('contracts.supplier'),
          dataIndex: 'reservaHotelName',
          key: 'reservaHotelName',
          visible: [USER_ROLE.RESELLER],
          width: COLUMN_WIDTH,
        },
        {
          title: t('contracts.reseller'),
          dataIndex: 'agentName',
          key: 'agentName',
          visible: [USER_ROLE.SUPPLIER],
          width: COLUMN_WIDTH,
        },
        {
          title: t('contracts.creation_date'),
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (createdAt: IContract['createdAt']) => <div>{dateFormatter.toUiFullMonth(createdAt)}</div>,
          visible: [USER_ROLE.RESELLER, USER_ROLE.SUPPLIER],
          width: COLUMN_WIDTH,
        },
        {
          title: t('contracts.status'),
          render: renderStatus,
          visible: [USER_ROLE.RESELLER, USER_ROLE.SUPPLIER],
          width: 80,
        },
        {
          title: t('contracts.modified'),
          key: 'updatedAt',
          dataIndex: 'updatedAt',
          render: (updatedAt: IContract['updatedAt']) => <div>{dateFormatter.toUiFullMonth(updatedAt)}</div>,
          visible: isActiveContracts ? [USER_ROLE.RESELLER, USER_ROLE.SUPPLIER] : [],
          width: COLUMN_WIDTH,
        },
        {
          title: <div className={styles.actionsLinksTitle}>{t('general.actions')}</div>,
          key: 'actions',
          visible: [USER_ROLE.RESELLER, USER_ROLE.SUPPLIER],
          render: renderActions,
          width: COLUMN_WIDTH,
        },
      ].filter(column => column?.visible?.includes(userRole)),
    [userRole],
  );

  return (
    <div data-testid="ContractsList" className={styles.root}>
      <Table
        loading={isLoading}
        columns={contractListColumns}
        dataSource={contracts}
        pagination={{
          current: pagination?.page,
          total: pagination?.total,
          pageSize: pagination?.limit,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        onChange={handlePageChange}
        rowKey="id"
        scroll={{ y: window.innerHeight - 320 }}
      />
    </div>
  );
};

export default ContractsList;
