import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { DatePicker } from 'antd';

import { DATE_API_FORMAT, TIME, FULL_DATE_TIME_FORMAT } from 'constants/date_formats';
import { PROPERTY_CHANNELS } from 'constants/property_channels';

import { IDateRangePickerProps, IDatesRange } from './date_range_picker.types';
import styles from './date_range_picker.module.scss';
import isCarProperty from 'utils/is_car_property';

const { RangePicker } = DatePicker;

const INPUT_CLASS_ACTIVE = 'ant-picker-input-active';

const DateRangePicker: React.FC<IDateRangePickerProps> = ({
  onCalendarChange,
  closedDates,
  defaultValue = [null, null],
  className,
  renderNumOfNights,
  allowEmpty = [true, true],
  bordered = false,
  disablePastDates = false,
  placeholder,
  size = 'middle',
  type = PROPERTY_CHANNELS.HOTEL,
  ranges,
  maxDate,
  maxDays,
  dateRange,
  closedDatesPerRate,
  showTime = false,
}) => {
  const dateOfPicker = document.getElementsByClassName('ant-picker-input');
  const isCar = isCarProperty(type);

  const getNumOfNights = (calendarDates = dates) => {
    if (!calendarDates || !calendarDates[0] || !calendarDates[1]) return 0;
    const daysDiff = Math.ceil(calendarDates[1].diff(calendarDates[0], 'minutes') / 60 / 24);
    return daysDiff;
  };

  const { t } = useTranslation();

  const [isPickerOpen, togglePicker] = useState<boolean>(false);
  const [dates, setDates] = useState<IDatesRange['dateRange']>(defaultValue);
  const [numberOfNights, setNumberOfNights] = useState(getNumOfNights());

  useEffect(() => {
    if (dateRange) {
      setDates(dateRange);
      togglePicker(false);
    }
  }, [dateRange]);

  const getIsDateClosed = useCallback(
    (date, ratePlanId?: string) => {
      if (ratePlanId && closedDatesPerRate) {
        const { closed } = closedDatesPerRate[ratePlanId];

        if (closed) return closed.includes(date.format(DATE_API_FORMAT));
        return false;
      } else if (closedDates) {
        const { closed } = closedDates;

        if (closed) return closed.includes(date.format(DATE_API_FORMAT));
        return false;
      }
      return false;
    },
    [closedDates, closedDatesPerRate],
  );

  const disabledDate = (date: Moment) => {
    const timeParam = isCar ? 'minutes' : 'days';
    if (getIsDateClosed(date)) return true;
    if (!disablePastDates) return false;
    if (dates?.[0] && dates?.[0].diff(date, timeParam) === 0) {
      return true;
    }
    if (dates?.[1] && dates?.[1].diff(date, timeParam) === 0) {
      return true;
    }

    if (maxDate && maxDate.diff(date, timeParam) <= 0) {
      return true;
    }

    if (maxDays && closedDatesPerRate) {
      let isClosedRange = false;
      for (const ratePlanId in closedDatesPerRate) {
        if (ratePlanId === 'type') {
          continue;
        }
        isClosedRange = false;

        for (let i = 0; i < maxDays; i++) {
          if (getIsDateClosed(moment(date).add(i, 'days'), ratePlanId)) {
            isClosedRange = true;
          }
        }
        // If an open range is found, no need to check other rate plans
        if (isClosedRange === false) {
          break;
        }
      }
      if (isClosedRange === true) {
        return true;
      }
    }

    if (maxDays && dateOfPicker?.[1]?.classList?.contains(INPUT_CLASS_ACTIVE) && dates?.[0] && dates?.[1]) {
      return true;
    }

    if (!maxDays && dateOfPicker?.[0]?.classList?.contains(INPUT_CLASS_ACTIVE) && dates?.[1]) {
      if (isDateClosedForCheckin(date)) {
        return true;
      }
    }

    if (!maxDays && dates?.[0] && dateOfPicker?.[1]?.classList?.contains(INPUT_CLASS_ACTIVE)) {
      if (isDateClosedForCheckout(date)) {
        return true;
      }
    }

    return 0 < moment().diff(date, 'days');
  };

  const handleDateChange = (dates: IDateRangePickerProps['defaultValue']) => {
    setDates(dates);
    onCalendarChange(dates);
    setNumberOfNights(getNumOfNights(dates));
  };

  const compareDates = (from: Moment, to: Moment) => {
    let daysDiff = to.diff(from, 'days');
    if (isCar && to.diff(from, 'minutes')) {
      daysDiff = daysDiff + 1;
    }
    if (0 <= daysDiff) return setNumberOfNights(daysDiff);
    return setNumberOfNights(0);
  };

  const handleDateHover = (date: Moment) => {
    if (dateOfPicker?.[0]?.classList?.contains(INPUT_CLASS_ACTIVE)) {
      if (!dates?.[1]) return;
      return compareDates(date, dates?.[1]);
    } else if (dateOfPicker[1].classList.contains(INPUT_CLASS_ACTIVE)) {
      if (!dates?.[0]) return;
      return compareDates(dates[0], date);
    }
  };

  const renderDateCell = (current: Moment) => (
    <div
      className="ant-picker-cell-inner"
      onMouseEnter={() => handleDateHover(current)}
      onMouseLeave={() => setNumberOfNights(getNumOfNights())}
    >
      {current.date()}
    </div>
  );

  const renderFooter = () => {
    if (!renderNumOfNights) return null;
    return (
      <div className={styles.number_of_nights}>{`${t(`date_picker_${type}.duration_unit`)} ${numberOfNights}`} </div>
    );
  };

  const isDateClosedForCheckin = (date: Moment) => {
    if (0 < moment(dates?.[1]).diff(date, 'days') && closedDates?.closed) {
      const hasClosedDateBetween = closedDates?.closed.find(d => {
        return d < moment(dates?.[1]).format(DATE_API_FORMAT) && moment(date).format(DATE_API_FORMAT) < d;
      });

      if (hasClosedDateBetween) {
        return true;
      }

      if (closedDatesPerRate) {
        let isClosedRange = true;

        for (const ratePlanId in closedDatesPerRate) {
          if (ratePlanId === 'type') {
            continue;
          }

          const hasClosedDate = closedDatesPerRate[ratePlanId].closed.find(
            d => d < moment(dates?.[1]).format(DATE_API_FORMAT) && moment(date).format(DATE_API_FORMAT) <= d,
          );

          if (!hasClosedDate) {
            isClosedRange = false;
            break;
          }
        }
        return isClosedRange;
      }
    }

    return false;
  };

  const isDateClosedForCheckout = (date: Moment) => {
    if (0 < date.diff(dates?.[0], 'days') && closedDates?.closed) {
      const hasClosedDateBetween = closedDates?.closed.find(d => {
        return 0 < moment(d).diff(dates?.[0], 'days') && 0 < date.diff(moment(d), 'days');
      });

      if (hasClosedDateBetween) {
        return true;
      }

      if (closedDatesPerRate) {
        let isClosedRange = true;

        for (const ratePlanId in closedDatesPerRate) {
          if (ratePlanId === 'type') {
            continue;
          }

          const hasClosedDate = closedDatesPerRate[ratePlanId].closed.find(
            d => moment(dates?.[0]).format(DATE_API_FORMAT) <= d && d < moment(date).format(DATE_API_FORMAT),
          );

          if (!hasClosedDate) {
            isClosedRange = false;
            break;
          }
        }
        return isClosedRange;
      }
    }

    return false;
  };

  if (!maxDays && dates?.[0] && dates?.[1]) {
    const defaultDates: IDatesRange['dateRange'] = JSON.parse(JSON.stringify(dates));
    if (isDateClosedForCheckout(dates?.[1]) && defaultDates?.[1]) {
      defaultDates[1] = null;
    }
    if (isDateClosedForCheckin(dates?.[0]) && defaultDates?.[0]) {
      defaultDates[0] = null;
    }

    if (JSON.stringify(dates) !== JSON.stringify(defaultDates)) {
      handleDateChange(defaultDates);
    }
  }

  const defaultTimeOptions = {
    format: TIME,
    defaultValue: [moment('10:00', TIME), moment('10:00', TIME)],
    minuteStep: 30,
  };

  return (
    <div data-testid="DateRangePicker">
      <RangePicker
        onChange={handleDateChange}
        allowEmpty={allowEmpty}
        bordered={bordered}
        disabledDate={disabledDate}
        defaultValue={defaultValue}
        value={dates}
        className={className}
        placeholder={placeholder || [t(`${type}_page.checkin_label`), t(`${type}_page.checkout_label`)]}
        renderExtraFooter={renderFooter}
        size={size}
        ranges={ranges}
        dateRender={renderDateCell}
        inputReadOnly={true}
        open={isPickerOpen}
        onOpenChange={togglePicker}
        showTime={showTime ? defaultTimeOptions : false}
        format={showTime ? FULL_DATE_TIME_FORMAT : DATE_API_FORMAT}
      />
    </div>
  );
};

export default DateRangePicker;
