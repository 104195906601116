import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Steps } from 'antd';

import DATE_FORMATTER from 'utils/date_formatter';
import PURCHASE_TYPE from 'constants/gift_card_purchase';

import { IGiftCardPurchaseDetailsFormProps } from './gift_card_purchase_details_form.types';
import { IGiftCardPurchase } from 'components/gift_card_purchases/gift_card_purchases.types';
import styles from './gift_card_details_form.module.scss';

const issuedStatuses = ['pending', 'queued', 'issued'];
const paymentStatuses = ['initiated', 'paid'];

const GiftCardPurchaseDetailsForm: React.FC<IGiftCardPurchaseDetailsFormProps> = ({ purchase }) => {
  const { t } = useTranslation();

  const {
    email,
    fullName,
    generatedId,
    referenceNumber,
    status,
    quantity,
    totalAmount,
    currency,
    issuedStatus,
    createdAt,
    emailSent,
  } = purchase;
  const formatLabel = useCallback((label: string) => `${t(label)}:`, []);

  const areGiftCardsIssued = (purchase: IGiftCardPurchase) => {
    return purchase.issuedStatus == PURCHASE_TYPE.ISSUED;
  };

  const productsLabel = (purchase: IGiftCardPurchase) => {
    return purchase.products.length > 1 ? t('gift_card.purchase.products') : t('gift_card.purchase.product');
  };

  return (
    <div data-testid="GiftCardPurchaseDetailsForm" className={styles.root}>
      {fullName && (
        <Row role="name" className={styles.row}>
          <Col span={8}>{formatLabel('general.other.full_name')}</Col>
          <Col span={16}>{fullName}</Col>
        </Row>
      )}
      {email && (
        <Row role="email" className={styles.row}>
          <Col span={8}>{formatLabel('general.other.email')}</Col>
          <Col span={16}>{email}</Col>
        </Row>
      )}
      {createdAt && (
        <Row role="createdAt" className={styles.row}>
          <Col span={8}>{formatLabel('general.created_at')}</Col>
          <Col span={16}>{DATE_FORMATTER.toUi(createdAt)}</Col>
        </Row>
      )}
      {generatedId && (
        <Row role="generatedId" className={styles.row}>
          <Col span={8}>{formatLabel('gift_card.purchase.generated_id')}</Col>
          <Col span={16}>{generatedId}</Col>
        </Row>
      )}
      {referenceNumber && (
        <Row role="referenceNumber" className={styles.row}>
          <Col span={8}>{formatLabel('gift_card.purchase.reference_number')}</Col>
          <Col span={16}>{referenceNumber}</Col>
        </Row>
      )}

      <Row role="status" className={styles.row}>
        <Col span={8}>{formatLabel('gift_card.purchase_status')}</Col>
        <Col span={16}>
          <Steps
            size="small"
            current={paymentStatuses.indexOf(status)}
            items={[{ title: 'Initiated' }, { title: 'Paid' }]}
          />
        </Col>
      </Row>

      <Row role="issuedStatus" className={styles.row}>
        <Col span={8}>{formatLabel('gift_card.issued_status')}</Col>
        <Col span={16}>
          <Steps
            size="small"
            current={issuedStatuses.indexOf(issuedStatus)}
            items={[{ title: 'Pending' }, { title: 'Queued' }, { title: 'Issued' }]}
          />
        </Col>
      </Row>

      {quantity && (
        <Row role="quantity" className={styles.row}>
          <Col span={8}>{formatLabel('gift_card.purchase.quantity')}</Col>
          <Col span={16}>{quantity}</Col>
        </Row>
      )}
      {totalAmount && (
        <Row role="totalAmount" className={styles.row}>
          <Col span={8}>{formatLabel('general.total_amount')}</Col>
          <Col span={16}>
            {totalAmount} {currency || 'kr'}
          </Col>
        </Row>
      )}
      <Row role="emailSent" className={styles.row}>
        <Col span={8}>{formatLabel('gift_card.purchase.email_sent')}</Col>
        <Col span={16}>{emailSent ? t('general.yes') : t('general.no')}</Col>
      </Row>

      {areGiftCardsIssued(purchase) && (
        <Row role="recipients" className={styles.row}>
          <Col span={8}>{t('gift_card.purchase.email_recipients')}</Col>
          <Col span={16}>
            {purchase.recipients.map(({ name, email }, index) => (
              <Row key={index}>
                <Col span={8}>{name}</Col>
                <Col span={8}>{email}</Col>
              </Row>
            ))}
          </Col>
        </Row>
      )}

      {purchase.products.length > 0 && (
        <Row role="products" className={styles.row}>
          <Col span={8}>{productsLabel(purchase)}</Col>
          <Col span={16}>
            {purchase.products.map((product, index) => (
              <Row key={index}>{`${product[1]} x ${product[0]}`}</Row>
            ))}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default GiftCardPurchaseDetailsForm;
