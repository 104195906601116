// Generated with util/create-component.js
import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormInstance } from 'antd/lib/form';
import { Button } from 'antd';

import moment from 'moment';

import { IGiftCardPdfMultipleFormsProps } from './gift_card_pdf_multiple_forms.types';
import GiftCardPdfForm from 'components/gift_card_pdf_details/gift_card_pdf_multiple_forms/gift_card_pdf_form/gift_card_pdf_form';
import styles from './gift_card_pdf_multiple_forms.module.scss';
import Loading from 'components/loading';
import { IFilledSection, IPdfSection } from 'components/gift_card_pdf_details/gift_card_pdf_details.types';
import notification from 'utils/notification/notification';
import DEFAULT_VALUES from 'constants/default_template_values';

const GiftCardPdfMultipleForms: React.FC<IGiftCardPdfMultipleFormsProps> = ({
  pdfSections,
  filledSections,
  onLoadFilledSections,
  onCancel,
  giftCard,
  selectedTemplateId,
  defaultTemplate,
  onSaveChanges,
}) => {
  const formsRef = useRef<FormInstance[]>([]);

  const handleFormCreated = (form: FormInstance) => {
    formsRef.current.push(form);
    return () => {
      formsRef.current = formsRef.current.filter(f => f !== form);
    };
  };
  const [allSections, setAllSections] = useState<IFilledSection[]>([]);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    const filledSectionsMap = filledSections.reduce<{ [sectionId: string]: IFilledSection }>((acc, filledSection) => {
      acc[filledSection.pdfSectionId] = filledSection;
      return acc;
    }, {});

    if (Object.keys(filledSectionsMap).length === 0 && defaultTemplate === true) {
      const defaultSections = pdfSections.map((section: IPdfSection) => {
        const templateValues = getDefaultTemplateValues(section.name || '');

        return { pdfSection: section, pdfSectionId: section.id, ...templateValues };
      });

      defaultSections.sort((a, b) => (moment(a?.pdfSection.id).isBefore(b?.pdfSection.id) ? -1 : 1));

      setAllSections(defaultSections);
    } else {
      const filledAndUnfilledSections = pdfSections.map((section: IPdfSection) => {
        if (filledSectionsMap[section.id]) {
          if (filledSectionsMap[section.id]?.photoUrl) {
            return { ...filledSectionsMap[section.id], photo: { url: filledSectionsMap[section.id].photoUrl } };
          }
          return filledSectionsMap[section.id];
        } else {
          return { pdfSection: section, pdfSectionId: section.id };
        }
      });

      filledAndUnfilledSections.sort((a, b) =>
        moment(a.pdfSection.createdAt).isBefore(b.pdfSection.createdAt) ? -1 : 1,
      );

      setAllSections(filledAndUnfilledSections);
    }
  }, [pdfSections, filledSections]);

  const handleSubmit = async () => {
    setSubmitLoading(true);

    const formValues: IFilledSection[] = [];
    for (const form of formsRef.current) {
      try {
        await form.validateFields();
        const allFields = form.getFieldsValue(true);
        formValues.push({
          ...allFields,
          ...(allFields.pdfSectionId && { pdfSectionId: allFields.pdfSectionId }),
          ...(allFields.id && { id: allFields.id }),
        });
      } catch (error) {
        console.error('Form validation failed:', error);
        setSubmitLoading(false);

        return;
      }
    }

    try {
      await onSaveChanges(formValues, selectedTemplateId, giftCard.id);
      notification.withIcon('success', t('gift_card.pdf_update_success'));
      onLoadFilledSections(giftCard.id, selectedTemplateId);
    } catch (e) {
      notification.withIcon('error', t('general.error_message'), t('general.error_description'));
    }

    setSubmitLoading(false);
    onCancel();
  };

  const getDefaultTemplateValues = (sectionName: string) => {
    switch (sectionName) {
      case 'Background':
        return DEFAULT_VALUES.background;
      case 'Hotel Logo':
        return null;
      case 'Main Photo':
        return null;
      case 'Gift Card Header':
        return DEFAULT_VALUES.giftCardHeader;
      case 'Gift Card Title':
        return { ...DEFAULT_VALUES.giftCardTitle, text: giftCard.title };
      case 'Gift Card Description':
        return {
          ...DEFAULT_VALUES.giftCardDescription,
          text: giftCard.description
            .replace(/<\/?p>/g, '')
            .replace(/&nbsp;/g, '')
            .replace(/<br\/?>/g, '\n'),
        };
      case 'Line Above Gift Card Code':
        return DEFAULT_VALUES.lineAboveGiftCardCode;
      case 'Gift Card Code':
        return DEFAULT_VALUES.giftCardCode;
      case 'Expiration Date':
        return DEFAULT_VALUES.expirationDate;
      case 'Line Below Expiration Date':
        return DEFAULT_VALUES.lineBelowExpirationDate;
      case 'Qr Code':
        return DEFAULT_VALUES.qrCode;
      case 'Booking Instructions':
        return DEFAULT_VALUES.bookingInstructions;
      case 'Hotel Information':
        return DEFAULT_VALUES.hotelInformation;
      case 'Box Around QR Code':
        return DEFAULT_VALUES.boxAroundQrCode;
      default:
        return null;
    }
  };

  return (
    <div data-testid="GiftCardPdfMultipleForms" className={styles.root}>
      {!allSections ? (
        <Loading />
      ) : (
        allSections.map((item: IFilledSection, index: number) => (
          <GiftCardPdfForm
            key={index}
            filledSection={item}
            defaultTemplate={defaultTemplate}
            onFormCreated={handleFormCreated}
          />
        ))
      )}
      <Button type="primary" onClick={handleSubmit} loading={submitLoading}>
        {t('gift_card.pdf_update_form')}
      </Button>
    </div>
  );
};

export default GiftCardPdfMultipleForms;
